import React from "react";
import moment from "moment";
import { Form, Input, DatePicker, InputNumber, Row, ConfigProvider } from "antd";
import locale from "antd/es/locale/hr_HR";
const { TextArea } = Input;
const { Search } = Input;

class SatnicaForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { step: 1, selected: {}, tipSatniceID: 1 };
    }

    vrsteKategorija = [];

    projektOnClick = (item) => {
        if (item.ProjektID == 0) {
            this.setState({ step: 2 });
            this.props.changeStep(2);
        } else {
            let nItem = this.state.selected;
            nItem.ItemID = item.ProjektID;
            nItem.ItemNaziv = item.Naziv;
            nItem.TipSatniceID = 1;
            this.props.formRef.current.setFieldsValue(nItem);
            this.setState({ step: 3, tipSatniceID: 1 });
            this.props.changeStep(3);
        }
    };

    ostaloOnClick = (item) => {
        let nItem = this.state.selected;
        nItem.ItemID = item.NeprojektnaKategorijaID;
        nItem.ItemNaziv = item.Naziv;
        nItem.TipSatniceID = 2;
        this.props.formRef.current.setFieldsValue(nItem);
        this.setState({ tipSatniceID: 2 });
        this.props.changeStep(3);
    };

    step = 3;

    componentDidUpdate = (prevProps) => {
        //    let satnicaID = this.props.formRef.current.getFieldsValue().SatnicaID;

        if (this.state.step != this.props.step || this.state.tipSatniceID != this.props.formRef.current.getFieldsValue().TipSatniceID) {
            this.setState({ step: this.props.step, tipSatniceID: this.props.formRef.current.getFieldsValue().TipSatniceID });
        }
    };

    onSearch = (e) => {
        this.props.onSearch(e);
    };

    onChange = (e) => {
        this.onSearch(e.target.value);
    };
    render() {
        const layout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 15 },
        };

        const dateFormat = "DD.MM.YYYY";

        return (
            <Form {...layout} ref={this.props.formRef}>
                {this.state.step == 0 ? (
                    <div>
                        <p style={{ color: "lightgrey" }}></p>

                        <div>
                            <div className="list-picker" onClick={() => this.props.changeStep(1)}>
                                Projekt
                            </div>
                            <div className="list-picker" onClick={() => this.props.changeStep(2)}>
                                Ostalo
                            </div>
                        </div>
                    </div>
                ) : null}

                {this.state.step == 1 ? (
                    <div>
                        <div>
                            <Input placeholder="Traži" onChange={this.onChange} style={{ width: "100%" }} />
                        </div>
                        {this.props.projekti.map((item, index) => {
                            return (
                                <div key={"projcs" + index}>
                                    <Row>
                                        <div className="list-picker" onClick={() => this.projektOnClick(item)}>
                                            {item.Sifra + " - " + item.Naziv}
                                        </div>
                                    </Row>
                                </div>
                            );
                        })}
                    </div>
                ) : null}

                {this.state.step == 2 ? (
                    <div>
                        Odaberi stavku
                        <br />
                        <br />
                        {this.props.kategorije.map((item, index) => {
                            return (
                                <div key={"ostl" + index}>
                                    <Row>
                                        <div key={""} onClick={() => this.ostaloOnClick(item)} className="list-picker">
                                            {item.Naziv}
                                        </div>
                                    </Row>
                                </div>
                            );
                        })}
                    </div>
                ) : null}

                {this.state.step == 3 ? (
                    <div>
                        <ConfigProvider locale={locale}>
                            <Form.Item name="ItemNaziv" label={"Stavka"}>
                                <Input
                                    className="pick-button"
                                    onClick={() => {
                                        let nItem = this.props.formRef.current.getFieldsValue();
                                        this.setState({ selected: nItem });
                                        this.props.changeStep(0);
                                        this.props.changeProject(nItem);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name="Datum" label="Datum" rules={[{ required: true, message: "Molimo unesite datum" }]}>
                                <DatePicker format={dateFormat} />
                            </Form.Item>
                            <Form.Item name="Kolicina" label="Količina" rules={[{ required: true, message: "Molimo unesite količinu" }]}>
                                <InputNumber placeholder="Količina (sati)" />
                            </Form.Item>

                            {this.state.tipSatniceID === 1 &&
                            <Form.Item name="KolicinaPrekovremeni" label="Prekovremeni" >
                                <InputNumber placeholder="Prekovremeni (sati)" />
                            </Form.Item>}
                            <Form.Item name="Opis" label="Opis">
                                <TextArea placeholder="Opis" />
                            </Form.Item>
                            <Form.Item name="SatnicaID">
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item hidden name="TipSatniceID">
                                <Input />
                            </Form.Item>
                            <Form.Item hidden name="ItemID">
                                <Input />
                            </Form.Item>
                        </ConfigProvider>
                    </div>
                ) : null}
            </Form>
        );
    }
}
export default SatnicaForm;
