import { Menu } from "antd";
import React from "react";
import { UserOutlined, DownOutlined, PercentageOutlined, EditOutlined, LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";
import { Row, Dropdown, Divider, Button, Modal, Form, Input, Popconfirm, Select, InputNumber, Switch } from "antd";

import Util from "../util/util";

const { SubMenu } = Menu;

class MainMenuMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
    }

    logout = () => {
        Util.logout();
        window.location = "/#/login";
    };

    setOpen = (data) => {
        this.setState({ isOpen: data });
        if (this.props.toggle) {
            this.props.toggle(data);
        }
    };

    onClick = (data) => {
        this.setState({ isOpen: false });
        if (data === "odjava") {
            Util.logout();
            window.location = "/#/login";
        }
    };

    render() {
        let isLoggedIn = this.props.isLoggedIn;
        let roles = [];
        let cookie = Util.getCookie("roles");
        if (cookie) roles = cookie.split(",");

        let isMobileDevice = Util.isMobileDevice();
        let standarniUpisnik = roles.includes("1");
        let administratorProjekata = roles.includes("2");
        let administratorKorisnika = roles.includes("4");
        let voditelj = roles.includes("8");

        const menu = (
            <Menu>
                <Menu.Item
                    key="mbm0"
                    onClick={() => {
                        window.location = "#/passwordchange";
                    }}
                >
                    Promjena lozinke
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                    key="mbm1"
                    onClick={() => {
                        Util.logout();
                    }}
                >
                    Odjava
                </Menu.Item>
            </Menu>
        );

        return (
            <Row style={{ display: "block", backgroundColor: "#2c3742", color: "white", textAlign: "right!important" }}>
                {isLoggedIn && (
                    <div style={{ margin: "10px", position: "fixed", color: "#f0f2f5", fontWeight: "bold" }}>
                        <Dropdown onClick={() => {this.setState({isOpen: false})}} overlay={menu} trigger={["click"]}>
                            <div>
                                <UserOutlined></UserOutlined> {this.props.username || ""}
                            </div>
                        </Dropdown>
                    </div>
                )}
                {isLoggedIn && (
                    <span className="hamby">
                        <Hamburger toggled={this.state.isOpen} toggle={this.setOpen} size={25} />
                    </span>
                )}
                {this.state.isOpen && (
                    <div className="hamby-content">
                        {isLoggedIn && standarniUpisnik ? (
                            <Row>
                                <Link className="hamby-item" onClick={() => this.onClick("dodaj")} to="/dodaj">
                                    Unos aktivnosti
                                </Link>
                            </Row>
                        ) : null}
                        {isLoggedIn && voditelj ? (
                            <Row>
                                <Link className="hamby-item" onClick={() => this.onClick("zp")} to="/zp">
                                    Završenost
                                </Link>
                            </Row>
                        ) : null}
                        {isLoggedIn && (
                            <Row>
                                <Link className="hamby-item" onClick={() => this.onClick("odjava")} to="/login">
                                    Odjava
                                </Link>
                            </Row>
                        )}
                    </div>
                )}
            </Row>
        );
    }
}
export default MainMenuMobile;
