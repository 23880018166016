import { Layout, Menu, Dropdown } from "antd";
import React from "react";
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, LineChartOutlined, FormOutlined, InfoCircleFilled, LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, Switch, Route, Redirect, HashRouter } from "react-router-dom";
import PregledKorisnika from "./pregledKorisnika";
import About from "./about";
import Home from "./home";
import Login from "./login";
import Lokacije from "./lokacije";
import Blagdani from "./blagdani";
import UnosAktivnosti from "./unosAktivnosti";
import Grupe from "./grupe";
import Korisnici from "./korisnici";
import Projekti from "./projekti";
import Izvjestaji from "./izvjestaji";
import Partneri from "./partneri";
import PasswordChange from "./passwordchange";
import Rangovi from "./rangovi";
import Struke from "./struke";
import ZavrsenostProjekta from "./zavrsenostProjekta";
import ZavrsenostProjekt from "./zavrsenostProjekt";
import PersonMobile from "./mobile/person";
import PersonDesktop from "./desktop/person";
import SampleForm from "./sampleform";
import MainMenu from "./mainmenu";
import MainMenuMobile from "./mainmenumobile";
import NotFound from "./notfound";
import Util from "../util/util";
import { browserHistory } from "react-router";
import { withRouter } from "react-router-dom";
import logo from "../images/Untitled-2.png";

const { Header, Sider, Content } = Layout;

const { SubMenu } = Menu;

class Navigation extends React.Component {
    constructor(props) {
        super(props);

        let user = Util.getLoggedInUser();
        let collapsed = Util.getCookie("navcollapsed");

        this.state = {
            collapsed: collapsed === "true" ? true : false,
            locationLabel: "",
            isMobileDevice: Util.isMobileDevice(),
            username: user ? user.username : "",
            user: user,
        };
    }

    toggle = () => {
        Util.setCookie("navcollapsed", !this.state.collapsed);
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    onLinkClick = (e) => {
        this.setState({ locationLabel: e.target.pathname });
    };

    login = () => {};

    onLoginSuccess = (user) => {
        var roles = user.roles.split(",");
        this.setState({ user: user, username: user.username, roles: roles });
        window.location = "/#";
        //window.location.reload();
    };

    menuToggle = (data) => {
        this.setState({ showMobileMenu: data });
    };

    render() {
        let logoClassName = this.state.collapsed ? "logoCollapsed" : "logo";
        logoClassName = "logo";
        let isLoggedIn = this.state.user != null;
        let notLoggedIn = !isLoggedIn;
        let isMobileDevice = Util.isMobileDevice();
        const menu = (
            <Menu>
                <Menu.Item
                    key="mbm0"
                    onClick={() => {
                        window.location = "#/passwordchange";
                    }}
                >
                    Promjena lozinke
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                    key="mbm1"
                    onClick={() => {
                        Util.logout();
                    }}
                >
                    Odjava
                </Menu.Item>
            </Menu>
        );

        return (
            <HashRouter>
                <Layout>
                    {isMobileDevice ? (
                        <MainMenuMobile username={this.state.user ? this.state.user.username : ""} roles={this.state.roles} isLoggedIn={isLoggedIn}></MainMenuMobile>
                    ) : (
                        <Sider theme="white" collapsedWidth={50} trigger={null} collapsible collapsed={this.state.collapsed}>
                            {this.state.collapsed ? (
                                <img src={logo} style={{ margin: "auto", height: "50px", width: "50px", float: "left" }}></img>
                            ) : (
                                // <div className={logoClassName}>
                                //     HP-ING <p style={{  fontSize: "9px", color: "rgb(134 134 134)", marginTop: "-9px" }}>project manager</p>

                                // </div>
                                <div className="menu-logo">
                                    <span style={{ marginTop: "20px", color: "white", display: "inline-block" }}>
                                        <p>HP-ING</p>
                                        <p style={{ marginTop: "-20px", fontSize: "9px", color: "#757575" }}>project manager</p>
                                    </span>
                                    <img src={logo} style={{ height: "64px", width: "64px", float: "left" }}></img>
                                </div>
                                // <Image className={"logo"} style={{ height: "64px", width: "64px", float: "right" }} src={logo}></Image>
                            )}
                            <MainMenu roles={this.state.roles} isLoggedIn={isLoggedIn}></MainMenu>
                        </Sider>
                    )}

                    <Layout className="site-layout">
                        {!isMobileDevice && (
                            <Header className="site-layout-background" style={{ padding: 0 }}>
                                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: "trigger",
                                    onClick: this.toggle,
                                })}
                                &nbsp;&nbsp;&nbsp;
                                <span style={{ fontSize: "17px", fontWeight: "bold" }}>{this.state.locationLabel}</span>
                                <div style={{ float: "right", marginRight: "20px" }}>
                                    <Dropdown overlay={menu} trigger={["click"]}>
                                        <div  style={{cursor: "pointer"}}>
                                            <UserOutlined></UserOutlined> {this.state.username || ""}
                                        </div>
                                    </Dropdown>
                                </div>
                            </Header>
                        )}
                        <Content
                            className="site-layout-background"
                            style={{
                                margin: "24px 16px",
                                padding: 24,
                                minHeight: 280,
                            }}
                        >
                            <Switch>
                                {isLoggedIn && <Route path="/pregledkorisnika" component={PregledKorisnika}></Route>}
                                {isLoggedIn && <Route path="/home" component={Home}></Route>}
                                {isLoggedIn && <Route path="/dodaj" component={UnosAktivnosti}></Route>}
                                {isLoggedIn && <Route path="/about" component={About}></Route>}
                                {isLoggedIn && <Route path="/lokacije" component={Lokacije}></Route>}
                                {isLoggedIn && <Route path="/blagdani" component={Blagdani}></Route>}
                                {isLoggedIn && <Route path="/partneri" component={Partneri}></Route>}
                                {isLoggedIn && <Route path="/korisnici" component={Korisnici}></Route>}
                                {isLoggedIn && <Route path="/projekti" component={Projekti}></Route>}
                                {isLoggedIn && <Route path="/izvjestaji" component={Izvjestaji}></Route>}
                                {isLoggedIn && <Route path="/grupe" component={Grupe}></Route>}
                                {isLoggedIn && <Route path="/rangovi" component={Rangovi}></Route>}
                                {isLoggedIn && <Route path="/struke" component={Struke}></Route>}
                                {isLoggedIn && <Route path="/zp" component={ZavrsenostProjekta}></Route>}
                                {isLoggedIn && <Route path="/zpp" component={ZavrsenostProjekt}></Route>}
                                {isLoggedIn && <Route path="/passwordchange" component={PasswordChange}></Route>}
                                {isLoggedIn && <Route path="/sampleform" component={SampleForm}></Route>}
                                {isLoggedIn && <Route path="/person/:username/:password" component={this.state.isMobileDevice ? PersonMobile : PersonDesktop}></Route>}
                                {isLoggedIn && <Route component={Home}></Route>}
                                {/* {isLoggedIn && <Route component={NotFound}></Route>} */}
                                {isLoggedIn && <Route path="/" component={() => <Login success={this.onLoginSuccess}></Login>}></Route>}
                                {notLoggedIn && <Route path="/" component={() => <Login success={this.onLoginSuccess}></Login>}></Route>}
                                {notLoggedIn && <Route path="/login" component={() => <Login success={this.onLoginSuccess}></Login>}></Route>}
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            </HashRouter>
        );
    }
}

export default Navigation;
