import React from "react";
import Util from "../util/util";
import { Form, Input, Select, DatePicker, InputNumber, ConfigProvider } from "antd";
import locale from "antd/es/locale/hr_HR";
const { Option } = Select;

class UgovorForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tipoviPartnera: [] };
    }

    render() {
        const layout = {
            labelCol: { span: 9 },
            wrapperCol: { span: 15 },
        };

        const dateFormat = "DD.MM.YYYY";

        return (
            <ConfigProvider locale={locale}>
                <Form {...layout} ref={this.props.formRef}>
                    <Form.Item name="BrojUgovora" label="Broj ugovora" rules={[{ required: true, message: "Molimo unesite broj ugovora" }]}>
                        <Input placeholder="Broj ugovora" />
                    </Form.Item>
                    <Form.Item name="Datum" label="Datum" rules={[{ required: true, message: "Molimo unesite datum" }]}>
                        <DatePicker format={dateFormat} />
                    </Form.Item>
                    <Form.Item name="Vrijednost" label="Vrijednost" rules={[{ required: true, message: "Molimo unesite vrijednost" }]}>
                        <InputNumber className="right" placeholder="Vrijednost" formatter={(value) => Util.CommaFormatted(value)} parser={(value) => value.replace(/\$\s?|(,*)/g, "")}  />
                    </Form.Item>
                </Form>
            </ConfigProvider>
        );
    }
}
export default UgovorForm;
