import auth_Header from "../util/authHeader";

class api {
    static host = process.env.NODE_ENV === "production" ? "https://hping.ipt.hr" : "https://localhost:44320";
    static api = process.env.NODE_ENV === "production" ? "https://hping.ipt.hr/api" : "https://localhost:44320";

    static get(url, data, sucess, fail) {
        fetch(this.api + "/" + url, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: auth_Header.authHeader(),
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (sucess) {
                    sucess(data);
                }
            })
            .catch((error) => {
                if (fail) {
                    fail(error.message);
                }
            });
    }

    static post(url, data, success, fail) {
        let requestOptions = {
            method: "POST",
            headers: auth_Header.authHeader(),
        };

        if (data) {
            requestOptions = {
                method: "POST",
                headers: auth_Header.authHeader(),
                body: JSON.stringify(data),
            };
        }

        (async () => {
            const rawResponse = await fetch(this.api + "/" + url, requestOptions);
            const content = await rawResponse.json();

            //if(content.ErrorMessage) {  alert(content.ErrorMessage)};

            if (success) success(content);

            console.log(content);
        })();
    }

    handleResponse(response) {
        let contentType = response.headers.get("content-type");
        if (contentType.includes("application/json")) {
            return this.handleJSONResponse(response);
        } else if (contentType.includes("text/html")) {
            return this.handleTextResponse(response);
        } else {
            throw new Error(`Sorry, content-type ${contentType} not supported`);
        }
    }

    handleJSONResponse(response) {
        return response.json().then((json) => {
            if (response.ok) {
                return json;
            } else {
                const error = json || (json && json.message) || (json.error && json.error_description) || response.statusText + " - " + response.status + " - " + response.responseJSON || response.Response;

                return Promise.reject(error);
            }
        });
    }

    handleTextResponse(response) {
        return response.text().then((text) => {
            if (response.ok) {
                //const data = text && JSON.parse(text);
                return text;
            } else {
                return Promise.reject(response.statusText + " - " + response.status + " - " + response.responseJSON);
            }
        });
    }

    checkStatus(response) {
        return response;
    }

    static login = (username, password, success, fail) => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: "grant_type=password&username=" + username + "&password=" + password,
        };

        fetch(this.api + "/token", requestOptions)
            .then((data) => {
                return data.json();
            })
            .then((data) => {
                if (success) {
                    success(data);
                }
            });
    };

    static getReport(url, inputData, success, fail) {
        var req = new XMLHttpRequest();
        let auth = auth_Header.authHeader();
        req.open("POST", this.api + "/" + url, true);
        req.setRequestHeader("Content-Type", "application/json");
        req.setRequestHeader("Authorization", auth.Authorization);
        req.responseType = "blob";

        req.onload = function (event) {
            var blob = req.response;
            var fn = req.getResponseHeader("Content-Disposition"); //if you have the fileName header available
            if (fn) {
                var idx = fn.indexOf("filename=");
                var fileName = fn;
                if (idx > -1) {
                    fileName = fn.substring(idx + 9);
                }
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                if (success) success({ Message: "Datoteka: " + fileName + " je uspješno preuzeta.", Status: 200, ResponseData: null });
            } else {
                if (fail) fail({ Message: "Greška pri generiranju ili preuzimanju datoteke.", Status: 505, ResponseData: blob });
            }
        };

        // req.onreadystatechange = function() {
        //     if (this.readyState == 4 && this.status == 200) {
        //     //console.log(this.responseText);
        //     }
        // }

        req.send(JSON.stringify(inputData));
    }
}

export default api;
