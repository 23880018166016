import React from "react";
import Util from "../util/util";

//...
class Person extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: props.match.params.username,
            password: props.match.params.password,
            details: this.details,
        };

    
        //alert(process.env.REACT_APP_API);
    }

    componentDidUpdate = () => {
        if (this.state.username !== this.props.match.params.username) {
            this.setState({
                username: this.props.match.params.username,
                password: this.props.match.params.password,
            });
            
        }
    };

    details = () => {
        return (
            <div>
                Username: {this.state.username}
                <br />
                Password: {this.state.password}
                
                <br />
            </div>
        );
    };
}
export default Person;
