import React from "react";
import api from "../util/api";
import { Row, Col, Divider, Button, Modal, message, Input, Popconfirm, Select, InputNumber, Switch } from "antd";
import LineIcon from "react-lineicons";
import Spinner from "../components/spinner";
import PartnerForm from "./partnerForm";
const { Option } = Select;

class Partneri extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], loading: true, showEditor: false, tipoviPartnera: [] };

        this.load();
    }

    load = () => {
        api.get("partneri/getall", null, this.success, this.fail);
    };

    success = (data) => {
        this.setState({ data: data.Partneri, tipoviPartnera: data.TipoviPartnera, loading: false });
    };

    fail = () => {
        this.setState({ data: null, loading: false });
    };

    showEditor = (item) => {
        if (item == null) {
            this.setState({ showEditor: true, selected: null });
            this.formRef.current.resetFields();
        } else {
            this.setState({ showEditor: true, selected: item });
            this.formRef.current.setFieldsValue(item);
        }
    };

    hideEditor = () => {
        this.setState({ showEditor: false });
    };

    saveChanges = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                let newValues = this.formRef.current.getFieldsValue();
                let newItem = { ...this.state.selected, ...newValues };

                this.setState({ selected: newItem, loading: true });

                api.post("partner/update", newItem, this.saveSuccess, this.saveFail);
                console.log(newItem);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    saveSuccess = (data) => {
        if (data.ErrorMessage) {
            this.setState({ loading: false });
            message.info(data.ErrorMessage);
        } else {
            this.setState({ showEditor: false, loading: false });
            this.load();
        }
    };

    saveFail = () => {};

    delete = (item) => {
        this.setState({ loading: true });

        api.post("partner/delete", item, this.deleteSuccess, this.deleteFail);
    };

    deleteSuccess = (data) => {
        
        if(data.ErrorMessage){
            message.info("Nije moguće obrisati partnera. \n" +  data.ErrorMessage);
            this.setState({ loading: false });
        }else{
           this.load();         
        }
    };

    deleteFail = () => {
        this.setState({ loading: false });
    };

    formRef = React.createRef();

    render() {
        return (
            <Row type="flex" justify="center" align="middle" style={{}}>
                {this.state.loading ? (
                    <Spinner></Spinner>
                ) : (
                    <Col span={24}>
                        {this.state.data.map((item, index) => {
                            return (
                                <Row key={"item" + index}>
                                    <Col span={1}>
                                        <LineIcon name="user" />
                                    </Col>
                                    <Col className="item" onClick={() => this.showEditor(item)} span={22}>
                                        {item.Naziv}
                                        {/* <br /> <span className="transparent"> {item.Ime + " " + item.Prezime}</span> */}
                                    </Col>
                                    <Col className="item" span={1}>
                                        <Popconfirm title="Sigurni ste da želite brisati partnera?" onConfirm={() => this.delete(item)} okText="DA" cancelText="NE">
                                            <LineIcon name="archive" />
                                        </Popconfirm>
                                    </Col>
                                    <Divider orientation="left"></Divider>
                                </Row>
                            );
                        })}
                        {/* <Button onClick={() => this.showEditor(null)}>Dodaj</Button> */}
                        <Row className="center">
                            <img onClick={() => this.showEditor(null)} className="add-button" src={process.env.PUBLIC_URL + "/add.png"} alt=""></img>
                        </Row>
                    </Col>
                )}
                <Modal forceRender title="Unos partnera" visible={this.state.showEditor} onOk={this.saveChanges} onCancel={this.hideEditor}>
                    <PartnerForm formRef={this.formRef} tipoviPartnera={this.state.tipoviPartnera}></PartnerForm>
                </Modal>
            </Row>
        );
    }
}
export default Partneri;
