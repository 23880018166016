import React from "react";

import { Form, Input, Select } from "antd";
const { Option } = Select;

class Partner extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tipoviPartnera: [] };
    }

    render() {
        const layout = {
            labelCol: { span: 9 },
            wrapperCol: { span: 15 },
        };

        const tipoviPartnera = this.props.tipoviPartnera.map((x) => (
            <Option value={x.TipPartneraID} key={x.TipPartneraID}>
                {x.Naziv}
            </Option>
        ));

        return (
            <Form {...layout} ref={this.props.formRef}>
                <Form.Item name="Naziv" label="Naziv" rules={[{ required: true, message: "Molimo unesite naziv" }]}>
                    <Input placeholder="Naziv" />
                </Form.Item>
                <Form.Item name="Adresa" label="Adresa" rules={[{ required: true, message: "Molimo unesite adresu" }]}>
                    <Input placeholder="Adresa" />
                </Form.Item>
                <Form.Item name="OIB" label="OIB" rules={[{ required: true, message: "Molimo unesite OIB" }]}>
                    <Input placeholder="OIB" />
                </Form.Item>
                <Form.Item label="Tip partnera" name="TipPartneraID">
                    <Select allowClear placeholder="">
                        {tipoviPartnera}
                    </Select>
                </Form.Item>
            </Form>
        );
    }
}
export default Partner;
