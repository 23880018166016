import React from "react";
import api from "../util/api";
import { Row, Col, Divider, Button, Modal, Form, Input, Popconfirm, Select, InputNumber, Switch } from "antd";
import LineIcon from "react-lineicons";
import Spinner from "../components/spinner";
import Util from "../util/util";
const { Option } = Select;

class Korisnici extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], loading: true, showEditor: false };

        if (!props.data) {
            this.load();
        } else {
            this.state = { data: props.data, loading: false };
        }
    }

    load = () => {
        api.get("korisnici/getall", null, this.success, this.fail);
    };

    success = (data) => {
        this.setState({ data: data.Korisnici, lokacije: data.Lokacije, rangovi: data.Rangovi, struke: data.Struke, grupe: data.Grupe, uloge: data.Uloge, loading: false });
    };

    fail = () => {
        this.setState({ data: null, loading: false });
    };

    showEditor = (item) => {
        if (item == null) {
            this.setState({ showEditor: true, selected: null });
            this.formRef.current.resetFields();
            item = { UpisObvezan: true };
            this.formRef.current.setFieldsValue(item);
        } else {
            this.setState({ showEditor: true, selected: item });
            this.formRef.current.setFieldsValue(item);
        }
    };

    hideEditor = () => {
        this.setState({ showEditor: false });
    };

    saveChanges = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                let newValues = this.formRef.current.getFieldsValue();
                let newItem = { ...this.state.selected, ...newValues };

                this.setState({ selected: newItem, loading: true, showEditor: false });

                api.post("korisnik/update", newItem, this.saveSuccess, this.saveFail);
                //console.log(newItem);
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    saveSuccess = (data) => {
        this.load();
    };

    saveFail = () => {};

    delete = (item) => {
        this.setState({ loading: true });
        api.post("korisnik/delete", item, this.deleteSuccess, this.deleteFail);
    };

    deleteSuccess = (res) => {
        if(res && res.ErrorMessage){
            alert(res.ErrorMessage);
        }
        this.load();
    };

    deleteFail = (data) => {
        this.setState({ loading: false });
    };

    determineColor = (item) => {
        var colorStyle = {};
        if(item.Aktivan){
            colorStyle.color = "black";
        }
        else{
            colorStyle.color = "red";
        }
        return colorStyle;
    }

    formRef = React.createRef();

    renderModal = () => {
        const layout = {
            labelCol: { span: 9 },
            wrapperCol: { span: 15 },
        };

        const lokacije = this.state.lokacije.map((x) => (
            <Option value={x.LokacijaID} key={x.LokacijaID}>
                {x.Naziv}
            </Option>
        ));

        const uloge = this.state.uloge.map((x) => (
            <Option value={x.UlogaID} key={x.UlogaID}>
                {x.Naziv}
            </Option>
        ));

        const grupe = this.state.grupe.map((x) => (
            <Option value={x.GrupaID} key={x.GrupaID}>
                {x.Naziv}
            </Option>
        ));

        const struke = this.state.struke.map((x) => (
            <Option value={x.StrukaID} key={x.StrukaID}>
                {x.Naziv}
            </Option>
        ));

        const rangovi = this.state.rangovi.map((x) => (
            <Option value={x.RangID} key={x.RangID}>
                {x.Naziv}
            </Option>
        ));

        return (
            <Modal width={1200} forceRender title="Unos korisnika" visible={this.state.showEditor} onOk={this.saveChanges} onCancel={this.hideEditor}>
                <Form {...layout} ref={this.formRef}>
                    <Row>
                        <Col span={12}>
                            <Form.Item autoComplete="off" name="Username" label="Korisničko ime (e-mail)" rules={[{ required: true, message: "Molimo unesite korisničko ime" }]}>
                                <Input autoComplete="off" placeholder="Korisničko ime" />
                            </Form.Item>
                            <Form.Item
                                name="Password"
                                label="Lozinka"
                                rules={[
                                    { required: true, message: "Molimo unesite lozinku" },
                                    { min: 6, message: "Lozinka mora sadržavati minimalno 6 znakova" },
                                ]}
                            >
                                <Input type="password" placeholder="Lozinka" />
                            </Form.Item>
                            <Form.Item name="Ime" label="Ime" rules={[{ required: true, message: "Molimo unesite ime" }]}>
                                <Input placeholder="Ime" />
                            </Form.Item>
                            <Form.Item name="Prezime" label="Prezime" rules={[{ required: true, message: "Molimo unesite prezime" }]}>
                                <Input placeholder="Prezime" />
                            </Form.Item>
                            <Form.Item name="OIB" label="OIB" rules={[{ required: true, message: "Molimo unesite OIB" }]}>
                                <Input placeholder="OIB" />
                            </Form.Item>
                            <Form.Item label="Uloge" name="Uloge" rules={[{ required: true, message: "Molimo odaberite uloge" }]}>
                                <Select mode="multiple" allowClear placeholder="">
                                    {uloge}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Lokacija" name="LokacijaID" rules={[{ required: true, message: "Molimo odaberite lokaciju" }]}>
                                <Select allowClear placeholder="">
                                    {lokacije}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Grupa" name="GrupaID">
                                <Select allowClear placeholder="">
                                    {grupe}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Struka" name="StrukaID" rules={[{ required: true, message: "Molimo odaberite struku" }]}>
                                <Select allowClear placeholder="">
                                    {struke}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Rang" name="RangID" rules={[{ required: true, message: "Molimo odaberite rang" }]}>
                                <Select allowClear placeholder="">
                                    {rangovi}
                                </Select>
                            </Form.Item>
                            <Form.Item name="OsnovnaPlaca" label="Osnovna plaća" rules={[{ required: true, message: "Molimo unesite plaću" }]}>
                                <InputNumber placeholder="Osnovna plaća" />
                            </Form.Item>
                            <Form.Item name="StazPosto" label="Staž(%)" rules={[{ required: true, message: "Molimo unesite staž(%)" }]}>
                                <InputNumber placeholder="Staž(%)" />
                            </Form.Item>
                            <Form.Item name="SatnicaPosto" label="Satnica (%)" rules={[{ required: true, message: "Molimo unesite satnicu(%)" }]}>
                                <InputNumber placeholder="Satnica(%)" formatter={(value) => Util.CommaFormatted(value)} parser={(value) => value.replace(/\$\s?|(,*)/g, "")} />
                            </Form.Item>
                            <Form.Item name="Broj" label="Broj">
                                <InputNumber placeholder="Broj" />
                            </Form.Item>
                            {/* <Form.Item name="Stimulacija" label="Stimulacija" rules={[{ required: true, message: "Molimo unesite stimulaciju" }]}>
                                <InputNumber placeholder="Stimulacija" />
                            </Form.Item> */}
                            <Form.Item valuePropName="checked" label="Upis obvezan" name="UpisObvezan">
                                <Switch />
                            </Form.Item>
                            <Form.Item valuePropName="checked" label="Aktivan" name="Aktivan">
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    };
    render() {
        return (
            <Row type="flex" justify="center" align="middle" style={{}}>
                {this.state.loading ? (
                    <Spinner></Spinner>
                ) : (
                    <Col span={24}>
                        <Row className="header">
                            <Col span={1} offset={1}>
                                Broj
                            </Col>
                            <Col span={4}>Ime i prezime</Col>
                            <Col span={4}>Struka</Col>
                            <Col span={4}>Rang</Col>
                            <Col className="right" span={4}>
                                Osnovna plaća
                            </Col>
                            <Col className="right" span={4}>
                                Staž(%)
                            </Col>
                        </Row>
                        <br></br>
                        {this.state.data.map((item, index) => {
                            return (
                                <Row key={"item" + index} className="row-item" onClick={() => this.showEditor(item)}>
                                    <Col span={1}>
                                        <LineIcon name="user" style={this.determineColor(item)}/>
                                    </Col>
                                    <Col span={1}>{item.Broj}</Col>
                                    <Col span={4}>
                                        {item.Ime + " " + item.Prezime}
                                        {/* <br /> <span className="transparent"> {item.Ime + " " + item.Prezime}</span> */}
                                    </Col>
                                    <Col span={4}>{this.state.struke.find((x) => x.StrukaID === item.StrukaID).Naziv}</Col>
                                    <Col span={4}>{this.state.rangovi.find((x) => x.RangID === item.RangID).Naziv}</Col>
                                    <Col className="right" span={4}>
                                        {Util.CommaFormatted(item.OsnovnaPlaca)}
                                    </Col>
                                    <Col className="right" span={4}>
                                        {Util.CommaFormatted(item.StazPosto)}
                                    </Col>
                                    <Col
                                        className="right"
                                        span={1}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <Popconfirm title="Sigurni ste da želite brisati korisnika?" onConfirm={() => this.delete(item)} okText="DA" cancelText="NE">
                                            <LineIcon name="archive" />
                                        </Popconfirm>
                                    </Col>
                                    {/* <Divider orientation="left"></Divider> */}
                                </Row>
                            );
                        })}
                        {/* <Button onClick={() => this.showEditor(null)}>Dodaj</Button> */}
                        <Row className="center">
                            <img onClick={() => this.showEditor(null)} className="add-button" src={process.env.PUBLIC_URL + "/add.png"} alt=""></img>
                        </Row>

                        {this.renderModal()}
                    </Col>
                )}
            </Row>
        );
    }
}
export default Korisnici;
