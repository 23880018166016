import React from "react";
import PartnerForm from "./partnerForm";
import api from "../util/api";
import { Form, Input, Select, Modal, Button, Anchor, InputNumber } from "antd";
import Util from "../util/util";
const { Option } = Select;
const { Link } = Anchor;

class KooperacijaForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    savePartner = () => {
        this.setState({ loadingPartner: true });
        this.partnerFR.current
            .validateFields()
            .then((values) => {
                let newValues = this.partnerFR.current.getFieldsValue();
                let newItem = { ...this.state.selected, ...newValues };

                this.setState({ selected: newItem, loading: true });

                api.post("partner/update", newItem, this.saveSuccess, this.saveFail);
                console.log(newItem);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    saveSuccess = (data) => {
        this.setState({ loading: false, showEditor: false, loadingPartner: false });
        this.props.addPartner(data);
        let values = this.props.formRef.current.getFieldsValue();
        values.PartnerID = data.PartnerID;
        this.props.formRef.current.setFieldsValue(values);
    };

    saveFail = () => {
        this.setState({ loading: false, showEditor: false, loadingPartner: false });
    };

    hideEditor = () => {
        this.setState({ showEditor: false });
    };

    partnerFR = React.createRef();

    kooperantOnChange = (data) => {
        if (data === -1) {
            let values = this.props.formRef.current.getFieldsValue();
            values.PartnerID = null;
            this.props.formRef.current.setFieldsValue(values);
            this.partnerFR.current.resetFields();
        }
    };

    showKooperantEditor = () => {
        this.setState({ showEditor: true });
        let values = this.props.formRef.current.getFieldsValue();
        values.PartnerID = null;
        this.props.formRef.current.setFieldsValue(values);
        this.partnerFR.current.resetFields();
    };

    render() {
        const layout = {
            labelCol: { span: 9 },
            wrapperCol: { span: 15 },
        };

        const kooperantPartneri = this.props.kooperantPartneri.map((x) => (
            <Option value={x.PartnerID} key={x.PartnerID}>
                {x.Naziv}
            </Option>
        ));

        kooperantPartneri.unshift(
            <Option value={-1} key={-1}>
                <Button onClick={this.showKooperantEditor}>Dodaj novog</Button>
            </Option>
        );

        const modalFooter = [
            <Button key="submit" onClick={this.hideEditor}>
                Cancel
            </Button>,
            <Button loading={this.state.loadingPartner} key="back" onClick={this.savePartner}>
                Spremi
            </Button>,
        ];

        return (
            <Form {...layout} ref={this.props.formRef}>
                <Form.Item label="Kooperant" name="PartnerID" rules={[{ required: true, message: "Molimo unesite kooperanta" }]}>
                    <Select
                        showSearch
                        //optionFilterProp="children"
                        filterOption={(input, option) => {
                            if (option.value === -1) return true;
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        //mode="multiple"
                        allowClear
                        placeholder=""
                        onChange={this.kooperantOnChange}
                    >
                        {kooperantPartneri}
                    </Select>
                </Form.Item>

                <Form.Item name="Vrijednost" label="Vrijednost" rules={[{ required: true, message: "Molimo unesite vrijednost" }]}>
                    <InputNumber formatter={(value) => Util.CommaFormatted(value)} parser={(value) => value.replace(/\$\s?|(,*)/g, "")} />
                </Form.Item>

                <Modal footer={modalFooter} forceRender title="Unos partnera" visible={this.state.showEditor} onCancel={this.hideEditor}>
                    <PartnerForm tipoviPartnera={this.props.tipoviPartnera} formRef={this.partnerFR}></PartnerForm>
                </Modal>
            </Form>
        );
    }
}
export default KooperacijaForm;
