import { Row, Col, Input, Button, DatePicker, Dropdown, Menu, Popconfirm, ConfigProvider, notification, InputNumber } from "antd";
import locale from "antd/es/locale/hr_HR";
import React from "react";
import api from "../util/api";
import Spinner from "../components/spinner";
import moment from "moment";
import Util from "../util/util";

class PregledKorisnika extends React.Component {
    constructor(props) {
        super(props);

        var d = new Date(); // current date
        d.setDate(1); // going to 1st of the month
        d.setHours(-1); // going to last hour before this date even started.

        this.state = { datum: moment(d), data: [], loading: true, showEditor: false, prosjecnaSatnica: 1, koeficijentFirme: 1 };

        if (!props.data) {
            this.load();
        } else {
            this.state = { data: props.data, loading: false };
        }
    }

    load = () => {
        api.get("korisnici/pregled", null, this.success, this.fail);
    };

    saveChanges = () => {
        // this.formRef.current
        //     .validateFields()
        //     .then((values) => {ssadaj
        // let newValues = this.formRef.current.getFieldsValue();
        // let newItem = { ...this.state.selected, ...newValues };

        this.setState({ loading: true });

        api.post("korisnici/pregledupdate", this.state.data, this.saveSuccess, this.saveFail);

        // })
        // .catch((err) => {
        //     console.log(err);
        // });
    };

    zakljuci = () => {
        this.setState({ loading: true });

        let newData = {
            Korisnici: this.state.data,
            Datum: this.state.datum.toDate(),
        };

        api.post("korisnici/pregledzakljuci", newData, this.success, this.saveFail);
    };

    saveSuccess = (data) => {
        this.load();
    };

    saveFail = () => {};

    success = (data) => {
        this.setState({ data: data.Korisnici, prosjecnaSatnica: data.ProsjecnaSatnica, minDate: data.ZadnjaZakljucnica ? data.ZadnjaZakljucnica.DatumDo : new Date(2000, 0, 1), zadnjaZakljucnica: data.ZadnjaZakljucnica, koeficijentFirme: data.KoeficijentFirme, loading: false });
        if (data.ErrorMessage) {
            //message.info(data.ErrorMessage);

            notification.open({
                message: data.ErrorMessage,
            });
        }
    };

    fail = () => {
        this.setState({ data: null, loading: false });
    };

    onChange = (e, index, polje) => {
        if (isNaN(e)) {
            let korisnici = this.state.data;
            korisnici[index][polje] = e.target.value;
            this.setState({ data: korisnici });
        } else {
            let korisnici = this.state.data;
            korisnici[index][polje] = e;
            this.setState({ data: korisnici });
        }
    };

    datumChange = (e) => {
        this.setState({ datum: e });
    };

    getBrutoTrosakPoSatu = (korisnik) => {
        return (((korisnik.OsnovnaPlaca * (1 + korisnik.StazPosto / 100) * this.state.koeficijentFirme) / ((this.state.prosjecnaSatnica * korisnik.SatnicaPosto) / 100)) * (1 + korisnik.Stimulacija / 100)).toFixed(2);
    };

    menuClick = () => {
        this.setState({ loading: true });

        api.post("korisnici/pregledzakljucnicaundo", null, this.success, this.saveFail);
    };

    render() {
        const dateFormat = "DD.MM.YYYY";

        const menu = (
            <Menu onClick={this.menuClick}>
                <Menu.Item key="1">Undo</Menu.Item>
            </Menu>
        );

        return this.state.loading ? (
            <Spinner></Spinner>
        ) : (
            <Row>
                <Col span={24}>
                    <table className="pregled-klijenata">
                        <thead>
                            <tr>
                                <td className="right" style={{ width: "80px" }}>Broj</td>
                                <td style={{ width: "200px" }}>Ime i prezime</td>
                                <td className="right">Osnovna plaća</td>
                                <td className="right">Staž(%)</td>
                                <td className="right">Stimulacija</td>
                                <td className="right">Brutto trošak po satu</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item, index) => {
                                return (
                                    <tr className="pregled-klijenata-tr" key={"klijent" + index}>
                                        <td>
                                            <div className="ant-input label right">{item.Broj}</div>
                                        </td>
                                        <td>
                                            <div className="ant-input label right">{item.Ime + " " + item.Prezime}</div>
                                        </td>
                                        <td>
                                            <div className="ant-input label right">{Util.CommaFormatted(item.OsnovnaPlaca)}</div>
                                        </td>
                                        <td>
                                            <InputNumber formatter={(value) => Util.CommaFormatted(value)} parser={(value) => value.replace(/\$\s?|(,*)/g, "")} value={item.StazPosto} onChange={(e) => this.onChange(e, index, "StazPosto")} />
                                        </td>
                                        <td>
                                            <InputNumber formatter={(value) => Util.CommaFormatted(value)} parser={(value) => value.replace(/\$\s?|(,*)/g, "")} value={item.Stimulacija} onChange={(e) => this.onChange(e, index, "Stimulacija")} />
                                        </td>
                                        <td>
                                            <div className="ant-input label right">{Util.CommaFormatted(this.getBrutoTrosakPoSatu(item))}</div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <Button type="primary" style={{ float: "right", margin: "20px" }} onClick={() => this.saveChanges()}>
                        Spremi izmjene
                    </Button>

                    <Popconfirm placement="topLeft" title={"Želite li zaključiti period?"} onConfirm={() => this.zakljuci()} okText="Da" cancelText="Ne">
                        <Button style={{ float: "right", margin: "20px", marginLeft: "-15px" }} type="primary" disabled={this.state.datum === null}>
                            Zaključi
                        </Button>
                    </Popconfirm>

                    <ConfigProvider locale={locale}>
                        <DatePicker disabledDate={(d) => !d || d.isBefore(moment(this.state.minDate).add(1, "days"))} allowClear={false} style={{ float: "right", margin: "20px" }} placeholder="Datum" format={dateFormat} onChange={this.datumChange} value={this.state.datum} />
                    </ConfigProvider>

                    {this.state.zadnjaZakljucnica && (
                        <Dropdown.Button type="primary" style={{ float: "right", margin: "20px" }} overlay={menu}>
                            {"Zadnja zaključnica: " + Util.datum(this.state.zadnjaZakljucnica.DatumDo)}
                        </Dropdown.Button>
                    )}
                </Col>
            </Row>
        );
    }
}
export default PregledKorisnika;
