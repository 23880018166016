import "./css/app.css";
import "./css/antd.css";
import "antd/dist/antd.css";
import Navigation from "./components/navigation";
import React from "react";
function App() {
    return <Navigation></Navigation>;
}

export default App;
