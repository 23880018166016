import React from "react";

import { Form, FormInstance, Input, Button, Alert, Row, Divider, Checkbox, Select, Option } from "antd";

import { UserOutlined, LockOutlined, DownloadOutlined } from "@ant-design/icons";
import api from "../util/api";
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { username: "", password: "", email: "", errorMessage: "", zaboravljenaLozinka: false, loading: false };
    }

    onFinish = (values) => {
        this.submit();
    };

    onFinishFailed = (errorInfo) => {
        //console.log("Failed:", errorInfo);
    };

    submit = (e) => {
        this.setState({ loading: true });
        setTimeout(() => {
            api.login(this.state.username, this.state.password, this.success, this.fail);
        }, 1000);
    };

    submitPromjenaLozinke = (e) => {
        this.setState({ loading: true });
        api.post("forgottenpasswordsendmail?email=" + this.state.email, null, this.sendMailSuccess, this.sendMailFail);
    };

    sendMailSuccess = (data) => {
        if (data.Status == 200) {
            this.setState({ loading: false, mailsent: true, zaboravljenaLozinka: false });
        } else {
            this.setState({ loading: false, mailsent: false });
        }

        this.setState({
            errorMessage: data.Message,
            loading: false,
        });
    };

    sendMailFail = (data) => {
        this.setState({ loading: false });
    };

    fail = (ex) => {
        this.setState({
            errorMessage: (
                <div>
                    {" "}
                    Neispravno korisničko ime ili lozinka &nbsp;{" "}
                    {/* <span
                        onClick={() => {
                            this.setState({ zaboravljenaLozinka: true, errorMessage: "" });
                        }}
                        style={{ textDecoration: "underline", fontSize: "12px", color: "blue", cursor: "pointer" }}
                    >
                        zaboravljena lozinka
                    </span>{" "} */}
                </div>
            ),
            loading: false,
        });
    };

    success = (user) => {
        if (user.error) {
            this.setState({ errorMessage: user.error_description, loading: false });
            return;
        }

        if (user) {
            user.authdata = window.btoa(this.state.username + ":" + this.state.password);

            // Build the expiration date string:
            let expiration_date = new Date();
            expiration_date.setFullYear(expiration_date.getFullYear() + 1);
            // Build the set-cookie string:
            let cookie_user = "user=" + JSON.stringify(user) + "; path=/; expires=" + expiration_date.toUTCString();
            let cookie_roles = "roles=" + user.roles + "; path=/; expires=" + expiration_date.toUTCString();
            // Create or update the cookie:
            document.cookie = cookie_user;
            document.cookie = cookie_roles;

            if (this.props.success) this.props.success(user);

            // window.location = "/#";
            // window.location.reload();
            // if (self.props.success) self.props.success();
        }
    };

    usernameChanged = (value) => {
        this.setState({ username: value.target.value });
    };

    passwordChanged = (value) => {
        this.setState({ password: value.target.value });
    };

    emailChanged = (value) => {
        this.setState({ email: value.target.value });
    };

    onKeyUp = (data) => {
        if (data.keyCode === 13) {
            this.submit();
        }
    };

    render() {
        return (
            <Row type="flex" justify="center" align="middle" style={{ minHeight: "80vh" }}>
                {this.state.mailsent ? (
                    <Form style={{ width: "250px" }} name="normal_login" className="login-form" initialValues={{}} onFinish={this.onFinish}>
                        {this.state.errorMessage != "" ? (
                            <Form.Item>
                                <Alert message={this.state.errorMessage}></Alert>
                            </Form.Item>
                        ) : null}
                    </Form>
                ) : null}

                {this.state.zaboravljenaLozinka ? (
                    <Form style={{ width: "250px" }} name="normal_login" className="login-form" initialValues={{}} onFinish={this.onFinish}>
                        <div style={{ fontSize: "12px", textAlign: "center" }}>Molimo unesite e-mail adresu na koji ćemo poslati link za obnavljanje lozinke</div>
                        <br />
                        <Form.Item name="email" rules={[{ required: true, message: "Molimo unesite e-mail adresu na koji ćemo poslati link za obnavljanje lozinke " }]}>
                            <Input onChange={this.emailChanged} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="e-mail" />
                        </Form.Item>
                        <Form.Item>
                            <Button onClick={this.submitPromjenaLozinke} loading={this.state.loading} style={{ width: "100%" }} className="search-button">
                                Pošalji
                            </Button>
                        </Form.Item>

                        {this.state.errorMessage != "" ? (
                            <Form.Item>
                                <Alert message={this.state.errorMessage}></Alert>
                            </Form.Item>
                        ) : null}
                    </Form>
                ) : null}

                {!this.state.zaboravljenaLozinka && !this.state.mailsent ? (
                    <Form style={{ width: "250px" }} name="normal_login" className="login-form" initialValues={{}} onFinish={this.onFinish}>
                        <Form.Item name="username" rules={[{ required: true, message: "Molimo unesite korisničko ime!" }]}>
                            <Input onChange={this.usernameChanged} onKeyUp={this.onKeyUp} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true, message: "Molimo unesite lozinku!" }]}>
                            <Input onChange={this.passwordChanged} onKeyUp={this.onKeyUp} prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
                        </Form.Item>

                        <Form.Item style={{ textAlign: "center" }}>
                            <Button onClick={this.submit} loading={this.state.loading} style={{ width: "100%" }} className="search-button">
                                Prijava
                            </Button>
                            {/* <span
                                onClick={() => {
                                    this.setState({ zaboravljenaLozinka: true, errorMessage: "" }); window.location = "#/loginsendmail";
                                }}
                                style={{ textDecoration: "underline",margin:"10px", display:"block", fontSize: "12px", color: "#989898", cursor: "pointer" }}
                            >
                                zaboravljena lozinka
                            </span> */}
                        </Form.Item>
                        {this.state.errorMessage != "" ? (
                            <Form.Item>
                                <Alert message={this.state.errorMessage}></Alert>
                            </Form.Item>
                        ) : null}

                        <Form.Item style={{ textAlign: "center" }}>
                        
                            <a href={process.env.PUBLIC_URL + "/project-manager.apk"}>Preuzmi android aplikaciju <DownloadOutlined></DownloadOutlined> </a>
                            {/* <span
                                onClick={() => {
                                    this.setState({ zaboravljenaLozinka: true, errorMessage: "" }); window.location = "#/loginsendmail";
                                }}
                                style={{ textDecoration: "underline",margin:"10px", display:"block", fontSize: "12px", color: "#989898", cursor: "pointer" }}
                            >
                                zaboravljena lozinka
                            </span> */}
                        </Form.Item>
                    </Form>
                ) : null}
            </Row>
        );
    }
}

export default Login;
