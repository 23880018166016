import React from "react";
import { Line } from "@ant-design/charts";
import { FieldTimeOutlined } from "@ant-design/icons";
import api from "../util/api";
import { Button, Row, Col } from "antd";
import Util from "../util/util";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataConfig: null,
        };
    }

    render() {
        let user = Util.getLoggedInUser();
        let isLoggedIn = user != null;
        let roles = [];
        let cookie = Util.getCookie("roles");
        if (cookie) roles = cookie.split(",");

        let isMobileDevice = Util.isMobileDevice();
        let standarniUpisnik = roles.includes("1");
        let administratorProjekata = roles.includes("2");
        let administratorKorisnika = roles.includes("4");
        let voditelj = roles.includes("8");
        // return <div>{ this.state.dataConfig ? <Line {...this.state.dataConfig}></Line> : null}

        // <button onClick={() => api.getReport("firstReport", null, null, null) }>
        //     First report
        // </button>
        // </div>;
        return (
            <Row type="flex" justify="center" align="middle" style={{ minHeight: "80vh" }}>
                <Col span={24} justify="center" align="middle">
                    {isLoggedIn && standarniUpisnik ? (
                        <Row justify="center">
                            <Button
                                onClick={() => {
                                    window.location = "/#/dodaj";
                                }}
                                style={{ height: "100px!important" }}
                                className="home-button"
                            >
                                <FieldTimeOutlined />
                                Unos aktivnosti
                            </Button>
                        </Row>
                    ) : null}

                    {isLoggedIn && voditelj ? (
                        <Row justify="center">
                            <Button
                                onClick={() => {
                                    window.location = "/#/zp";
                                }}
                                style={{ height: "100px!important" }}
                                className="home-button"
                            >
                                Završenost projekta
                            </Button>
                        </Row>
                    ) : null}
                </Col>
            </Row>
        );
    }
}
export default Home;
