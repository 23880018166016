import React from "react";
import api from "../util/api";
import { message, Row, Col, Divider, Button, Modal, Form, Input, Popconfirm, Select, InputNumber, Pagination, DatePicker, Switch, ConfigProvider } from "antd";
import LineIcon from "react-lineicons";
import Spinner from "../components/spinner";
import UgovorForm from "./ugovorForm";
import Header from "./header";
import KooperacijaForm from "./kooperacijaForm";
import util from "../util/util";
import moment from "moment";
import locale from "antd/es/locale/hr_HR";
import { getAllByPlaceholderText } from "@testing-library/dom";
const { Option } = Select;
const { Search } = Input;
const { TextArea } = Input;

class Projekti extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], voditelji: [], pageSize: 20, pageNumber: 1, loading: true, showEditor: false, searchText: "" };

        this.load();
    }

    pager = { CurrentPage: 1, PageSize: 20, SortIndex: 0, SortOrder: "Naziv ASC", SearchText: "" };

    load = () => {
        api.post("projekti/getall", this.pager, this.success, this.fail);
    };

    success = (data) => {
        this.setState({ data: data.Pager.Data, zadnjiProjektID: data.ZadnjiProjektID, tipoviAlerta: data.TipoviAlerta, tipoviPartnera: data.TipoviPartnera, ugovori: [], kooperacija: [], pagerCount: data.Pager.ItemsCount, investitorPartneri: data.InvestitorPartneri, kooperantPartneri: data.KooperantPartneri, voditelji: data.Voditelji, loading: false });
        //console.error(data);
    };

    fail = () => {
        this.setState({ data: null, loading: false });
    };

    showEditor = (item) => {
        if (item == null) {
            this.setState({ showEditor: true, selected: null, ugovori: [], kooperacija: [] });
            this.formRef.current.resetFields();
            let item = this.formRef.current.getFieldsValue();
            item.Sifra = this.state.zadnjiProjektID + 1;
            this.formRef.current.setFieldsValue(item);
        } else {
            item.DatumPocetak = moment(new Date(item.DatumPocetak));
            item.DatumKraj = moment(new Date(item.DatumKraj));
            item.DatumZadnjeFakture = item.DatumZadnjeFakture ? moment(new Date(item.DatumZadnjeFakture)) : null;
            this.setState({ showEditor: true, selected: item, ugovori: [...item.Ugovori], kooperacija: [...item.Kooperacija] });
            this.formRef.current.setFieldsValue(item);
        }
    };

    hideEditor = () => {
        this.setState({ showEditor: false });
    };

    selectedKooperacijaIndex = -1;
    showKooperacija = (item, index) => {
        if (item == null) {
            this.selectedKooperacijaIndex = -1;
            this.setState({ showKooperacija: true });
            this.kooperacijaFormRef.current.resetFields();
        } else {
            this.selectedKooperacijaIndex = index;
            this.setState({ showKooperacija: true });
            this.kooperacijaFormRef.current.setFieldsValue(item);
        }
    };

    addKooperacija = () => {
        this.kooperacijaFormRef.current
            .validateFields()
            .then((values) => {
                let newValues = this.kooperacijaFormRef.current.getFieldsValue();
                let newItem = { ...newValues };
                newItem.Naziv = this.state.kooperantPartneri.find((x) => {
                    return x.PartnerID == newItem.PartnerID;
                }).Naziv;

                let kooperacija = this.state.kooperacija;
                if (this.selectedKooperacijaIndex == -1) {
                    kooperacija.push(newItem);
                } else {
                    kooperacija[this.selectedKooperacijaIndex] = newItem;
                }

                this.setState({ kooperacija: kooperacija, showKooperacija: false });
            })
            .catch((err) => {});
    };

    removeKooperacija = (index) => {
        let kooperacija = this.state.kooperacija;
        kooperacija.splice(index, 1);
        this.setState({ kooperacija: kooperacija });
    };

    selectedUgovorIndex = -1;
    showUgovor = (item, index) => {
        if (item == null) {
            this.selectedUgovorIndex = -1;
            this.setState({ showUgovor: true });
            this.ugovorFormRef.current.resetFields();
        } else {
            this.selectedUgovorIndex = index;
            item.Datum = moment(new Date(item.Datum));
            this.setState({ showUgovor: true });
            this.ugovorFormRef.current.setFieldsValue(item);
        }
    };

    addUgovor = () => {
        this.ugovorFormRef.current
            .validateFields()
            .then((values) => {
                let newValues = this.ugovorFormRef.current.getFieldsValue();
                let newItem = { ...newValues };

                let ugovori = this.state.ugovori;
                if (this.selectedUgovorIndex == -1) {
                    ugovori.push(newItem);
                } else {
                    ugovori[this.selectedUgovorIndex] = newItem;
                }

                this.setState({ ugovori: ugovori, showUgovor: false });
            })
            .catch((err) => {});
    };

    removeUgovor = (index) => {
        let ugovori = this.state.ugovori;
        ugovori.splice(index, 1);
        this.setState({ ugovori: ugovori });
    };

    saveChanges = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                let newValues = this.formRef.current.getFieldsValue();
                let newItem = { ...this.state.selected, ...newValues };

                if (this.state.ugovori.length === 0) {
                    alert("Potrebno je unijeti barem jedan ugovor.");
                    return;
                }
                newItem.Ugovori = [...this.state.ugovori];
                newItem.Kooperacija = [...this.state.kooperacija];

                this.setState({ selected: newItem, loading: true, showEditor: false });
                newItem.Aktivan = newItem.Aktivan ? 1 : 0;
                newItem.ProjektID = this.state.selected.ProjektID;
                api.post("projekt/update", newItem, this.saveSuccess, this.saveFail);
            })
            .catch((err) => {});
    };

    saveSuccess = (data) => {
        this.load();
    };

    saveFail = () => {};

    delete = (item) => {
        this.setState({ loading: true });
        api.post("projekt/delete", item, this.deleteSuccess, this.deleteFail);
    };

    deleteSuccess = (data) => {
        if (data.ErrorMessage) {
            message.error(data.ErrorMessage);
        }
        this.load();
    };

    deleteFail = () => {
        this.setState({ loading: false });
    };

    formRef = React.createRef();
    ugovorFormRef = React.createRef();
    kooperacijaFormRef = React.createRef();

    pageChange = (page) => {
        this.setState({ pageNumber: page, loading: true });
        this.pager = { CurrentPage: page, PageSize: this.state.pageSize, SortIndex: 0, SortOrder: "Naziv ASC", SearchText: this.state.searchText };
        this.load();
    };

    onSearch = (data) => {
        this.setState({ pageNumber: 1, searchText: data, loading: true });
        this.pager = { CurrentPage: 1, PageSize: this.state.pageSize, SortIndex: 0, SortOrder: "Naziv ASC", SearchText: data };
        this.load();
    };

    addPartner = (data) => {
        let kooperantPartneri = [...this.state.kooperantPartneri];
        kooperantPartneri.push(data);
        this.setState({ kooperantPartneri: kooperantPartneri });
    };

    renderModal = () => {
        const layout = {
            labelCol: { span: 9 },
            wrapperCol: { span: 15 },
        };

        const investitorPartneri = this.state.investitorPartneri.map((x) => (
            <Option value={x.PartnerID} key={x.PartnerID}>
                {x.Naziv}
            </Option>
        ));

        const voditelji = this.state.voditelji.map((x) => (
            <Option value={x.KorisnikID} key={x.KorisnikID}>
                {x.Ime + " " + x.Prezime}
            </Option>
        ));

        const tipoviAlerta = this.state.tipoviAlerta.map((x) => (
            <Option value={x.TipAlertID} key={"ta" + x.TipAlertID}>
                {x.Naziv}
            </Option>
        ));

        const dateFormat = "DD.MM.YYYY";

        return (
            <Modal width={1500} forceRender title="Projekt" visible={this.state.showEditor} onOk={this.saveChanges} onCancel={this.hideEditor}>
                <ConfigProvider locale={locale}>
                    <Form {...layout} ref={this.formRef}>
                        <Row>
                            <Col span={8}>
                                <Form.Item name="Naziv" label="Naziv" rules={[{ required: true, message: "Molimo unesite naziv" }]}>
                                    <Input placeholder="Naziv" />
                                </Form.Item>
                                <Form.Item name="DatumPocetak" label="Datum početak" rules={[{ required: true, message: "Molimo unesite datum početak" }]}>
                                    <DatePicker format={dateFormat} />
                                </Form.Item>
                                <Form.Item name="DatumKraj" label="Datum kraj" rules={[{ required: true, message: "Molimo unesite datum početak" }]}>
                                    <DatePicker format={dateFormat} />
                                </Form.Item>
                                <Form.Item name="Sifra" label="Šifra" rules={[{ required: true, message: "Molimo unesite šifru" }]}>
                                    <InputNumber placeholder="Šifra" />
                                </Form.Item>
                                <Form.Item name="Broj" label="Broj">
                                    <Input placeholder="Broj" />
                                </Form.Item>
                                <Form.Item name="Naplaceno" label="Naplaćeno">
                                    <InputNumber placeholder="Naplačeno" />
                                </Form.Item>
                                <Form.Item label="Datum zadnje fakture" name="DatumZadnjeFakture">
                                    <DatePicker placeholder="Datum zadnje fakture" format={dateFormat} />
                                </Form.Item>
                                <Form.Item label="Investitor partner" name="InvestitorPartnerID" rules={[{ required: true, message: "Molimo odaberite partner investitora" }]}>
                                    <Select
                                        allowClear
                                        placeholder=""
                                        showSearch
                                        filterOption={(input, option) => {
                                            if (option.value === -1) return true;
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}
                                    >
                                        {investitorPartneri}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Voditelj" name="VoditeljKorisnikID" rules={[{ required: true, message: "Molimo unesite voditelja" }]}>
                                    <Select
                                        //optionFilterProp="children"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        //mode="multiple"
                                        allowClear
                                        placeholder=""
                                    >
                                        {voditelji}
                                    </Select>
                                </Form.Item>

                                <Form.Item name={"Opis"} label="Opis">
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item name={"OpisObracunReport"} label="Opis za skupni izvještaj">
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item label="Alerti" name="Alerti">
                                    <Select mode="multiple" allowClear placeholder="">
                                        {tipoviAlerta}
                                    </Select>
                                </Form.Item>
                                <Form.Item valuePropName="checked" label="Aktivan" name="Aktivan">
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col span={8} style={{ paddingLeft: 12 }}>
                                <Button style={{ marginBottom: "12px" }} onClick={() => this.showKooperacija()}>
                                    Dodaj Kooperaciju
                                </Button>
                                <Row className="header">
                                    <Col span={23}>
                                        <Row>
                                            <Col offset={2} span={12}>
                                                Kooperant
                                            </Col>
                                            <Col span={10}>Vrijednost</Col>
                                        </Row>
                                    </Col>
                                    <Col span={1}></Col>
                                </Row>

                                {this.state.kooperacija.map((item, index) => {
                                    return (
                                        <Row key={"kooperacija" + index}>
                                            <Col span={23}>
                                                <Row className="item" onClick={() => this.showKooperacija(item, index)}>
                                                    <Col offset={2} span={12}>
                                                        {item.Naziv}
                                                    </Col>
                                                    <Col span={10}>{util.CommaFormatted(item.Vrijednost)}</Col>
                                                </Row>
                                            </Col>
                                            <Col onClick={() => this.removeKooperacija(index)} span={1} style={{ cursor: "pointer" }}>
                                                <LineIcon name="trash" />
                                            </Col>
                                        </Row>
                                    );
                                })}
                                <Divider dashed />
                                <Modal forceRender title="Unos kooperanta" visible={this.state.showKooperacija} onOk={this.addKooperacija} onCancel={() => this.setState({ showKooperacija: false })}>
                                    <KooperacijaForm addPartner={this.addPartner} tipoviPartnera={this.state.tipoviPartnera} kooperantPartneri={this.state.kooperantPartneri} formRef={this.kooperacijaFormRef}></KooperacijaForm>
                                </Modal>
                            </Col>
                            <Col span={8} style={{ paddingLeft: 12 }}>
                                <Button style={{ marginBottom: "12px" }} onClick={() => this.showUgovor()}>
                                    Dodaj ugovor
                                </Button>
                                <Row className="header">
                                    <Col span={23}>
                                        <Row>
                                            <Col offset={2} span={7}>
                                                Broj ugovora
                                            </Col>
                                            <Col span={5}>Datum</Col>
                                            <Col span={5}>Vrijednost</Col>
                                        </Row>
                                    </Col>
                                    <Col span={1}></Col>
                                </Row>
                                {this.state.ugovori.map((ugovor, index) => {
                                    return (
                                        <Row key={"ugovor" + index}>
                                            <Col span={23}>
                                                <Row className="item" onClick={() => this.showUgovor(ugovor, index)}>
                                                    <Col offset={2} span={7}>
                                                        {ugovor.BrojUgovora}
                                                    </Col>
                                                    <Col span={5}>{util.datum(ugovor.Datum)}</Col>
                                                    <Col span={5}>{util.CommaFormatted(ugovor.Vrijednost)}</Col>
                                                </Row>
                                            </Col>
                                            <Col onClick={() => this.removeUgovor(index)} span={1} style={{ cursor: "pointer" }}>
                                                <LineIcon name="trash" />
                                            </Col>
                                        </Row>
                                    );
                                })}
                                <Divider dashed />
                                <Modal forceRender title="Unos ugovora" visible={this.state.showUgovor} onOk={this.addUgovor} onCancel={() => this.setState({ showUgovor: false })}>
                                    <UgovorForm formRef={this.ugovorFormRef}></UgovorForm>
                                </Modal>
                            </Col>
                        </Row>
                    </Form>
                </ConfigProvider>
            </Modal>
        );
    };
    render() {

        let columns =  [{name:"Naziv",width: 6},{name:"Broj", width:2}, {name:"Investitor", width:6}];

        return (
            <>
                <Row>
                    <Col span={24}>
                        <Search placeholder="Pretraga" onSearch={this.onSearch} style={{ marginBottom: "30px", float: "right", width: 200 }} />
                    </Col>
                </Row>
                <Row type="flex" justify="center" align="middle" style={{}}>
                    {this.state.loading ? (
                        <Spinner></Spinner>
                    ) : (
                        <Col span={24}>
                            
                            <Header columns={columns} sortIndex={-1} sortOrder={0}></Header>

                            <br></br>
                            {this.state.data.map((item, index) => {
                                return (
                                    <Row key={"item" + index} className="row-item" onClick={() => this.showEditor(item)}>
                                        <Col span={1}>
                                            <LineIcon name="ruler-pencil" />
                                        </Col>
                                        <Col span={6}>
                                            {item.Sifra + " - " + item.Naziv}
                                            {/* <br /> <span className="transparent"> {item.Ime + " " + item.Prezime}</span> */}
                                        </Col>
                                        <Col span={2}>{item.Broj}</Col>
                                        <Col span={13}>{item.InvestitorNaziv}</Col>
                                        <Col
                                            span={1}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            <Popconfirm title="Sigurni ste da želite brisati projekt?" onConfirm={() => this.delete(item)} okText="DA" cancelText="NE">
                                                <LineIcon name="archive" />
                                            </Popconfirm>
                                        </Col>
                                    </Row>
                                );
                            })}
                            {/* <Button onClick={() => this.showEditor(null)}>Dodaj</Button> */}
                            <br />
                            <br />
                            <Pagination style={{ float: "right" }} size="small" total={this.state.pagerCount} onChange={this.pageChange} current={this.state.pageNumber} pageSize={this.state.pageSize} showSizeChanger={false} />

                            <Row className="center">
                                <img onClick={() => this.showEditor(null)} className="add-button" src={process.env.PUBLIC_URL + "/add.png"} alt=""></img>
                            </Row>

                            {this.renderModal()}
                        </Col>
                    )}
                </Row>
            </>
        );
    }
}
export default Projekti;
