import React from "react";
import api from "../util/api";
import { Row, Col, Divider, Button, Modal, Form, Input, DatePicker, InputNumber, Slider, Popconfirm, Progress, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import LineIcon from "react-lineicons";
import Spinner from "../components/spinner";
import Util from "../util/util";
import moment from "moment";

class ZavrsenostProjekta extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], loading: true, isMobileDevice: Util.isMobileDevice() };

        if (!props.data) {
            this.load();
        } else {
            this.state = { data: props.data, loading: false };
        }
    }

    load = () => {
        api.get("zavrsenostprojekta/getprojektibyvoditelj", null, this.success, this.fail);
    };

    success = (data) => {
        console.log(data)
        if (data.ErrorMessage) {
            message.error(data.ErrorMessage);
        }
        this.setState({ data: data || [], loading: false });
    };

    fail = (data) => {
        console.log(data)
        if (data.ErrorMessage) {
            message.info(data.ErrorMessage);
        }
        this.setState({ data: null, loading: false });
    };

    showZP = (item) => {
        window.location = "#/zpp?id=" + item.ProjektID + "&sifra=" + item.Sifra + "&naziv=" + item.Naziv;
    };

    render() {
        return (
            <Row type="flex" justify="center" align="middle" style={{}}>
                {this.state.loading ? (
                    <Spinner></Spinner>
                ) : (
                    <Col span={24}>
                        {this.state.data.length > 0 ? (
                            this.state.data.map((item, index) => {
                                return (
                                    <Row key={"item" + index} className="row-item" onClick={() => this.showZP(item)}>
                                        <Col span={17}>
                                            {item.Sifra + " - " + item.Naziv} <br /> <span className="transparent"> {item.Adresa}</span>
                                        </Col>
                                        <Col span={7} style={{ textAlign: "left" }}>
                                            <Progress
                                                strokeColor={{
                                                    from: "#108ee9",
                                                    to: "#87d068",
                                                }}
                                                percent={item.Zavrsenost}
                                                size="small"
                                            />
                                        </Col>

                                        {/* <Divider orientation="left"></Divider> */}
                                    </Row>
                                );
                            })
                        ) : (
                            <p className="grey">Nemate aktivnih projekata</p>
                        )}
                    </Col>
                )}
            </Row>
        );
    }
}
export default ZavrsenostProjekta;
