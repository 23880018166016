import React from "react";
import api from "../util/api";
import { Row, Col, Divider, Button, Modal, message, Input, Affix, Popconfirm, Pagination } from "antd";
import LineIcon from "react-lineicons";
import Spinner from "./spinner";
import Util from "../util/util";
import moment from "moment";
import "moment/locale/hr";
import { EditOutlined, PlusOutlined, CheckCircleOutlined } from "@ant-design/icons";
import SatnicaForm from "./satnicaForm";

const itemsPerPage = 20;

class UnosAktivnosti extends React.Component {
    constructor(props) {
        super(props);

        this.state = { data: [], formRef: React.createRef(), loading: true, showEditor: false, showModal: false, projekti: [], projekt: null, kategorije: [], step: -1, current: 0, minIndex: 0, maxIndex: itemsPerPage };

        if (!props.data) {
            this.load(1);
        } else {
            this.state = { data: props.data, satnice: [], loading: false };
        }
    }

    load = (page) => {
        //api.get("unosaktivnosti/get", null, this.success, this.fail);
        this.setState({loading: true});
        var pager = { CurrentPage: page, PageSize: itemsPerPage, SortIndex: 0, SortOrder: "", SearchText: "" };
        api.post("unosaktivnosti/getpage", pager, this.success, this.fail);
    };

    success = (data) => {
        if (data.ErrorMessage) {
            message.error(data.ErrorMessage);
        }
        //data.Projekti.push({ Naziv: "Ostalo", ProjektID: 0 });
        this.setState({ data: data.Satnice, loading: false, projekti: data.Projekti || [], kategorije: data.NeprojektneKategorije, numberOfPages: data.BrojSvihSatnica});
        this.sviProjekti = data.Projekti;
        this.selectLast();
    };

    sviProjekti = [];

    fail = (data) => {
        this.setState({ data: [], loading: false });
        if (data.ErrorMessage) {
            message.error(data.ErrorMessage);
        }
    };

    lastItemID = null;

    selectLast = () => {
        var elmnt = document.getElementById(this.lastItemID);
        if (elmnt) elmnt.scrollIntoView();
    };

    selectedSatnicaIndex = -1;
    scrollTop = 0;
    saveChanges = () => {
        this.scrollTop = document.getElementById("satnice").scrollTop;
        this.state.formRef.current
            .validateFields()
            .then((values) => {
                let newValues = this.state.formRef.current.getFieldsValue();
                let newItem = { ...this.state.selected, ...newValues };

                if (newItem.TipSatniceID === 2) {
                    newItem.KolicinaPrekovremeni = 0;
                    if (newItem.Kolicina === 0) {
                        alert("Potrebno je upisati kolicinu sati.");
                        return;
                    }
                }

                if (newItem.Kolicina === 0) {
                    if (!newItem.KolicinaPrekovremeni || newItem.KolicinaPrekovremeni < 1) {
                        alert("Potrebno je upisati prekovremene sate ako je količina 0.");
                        return;
                    }
                }

                if (JSON.stringify(newValues) === "{}") {
                    alert("Potrebno je odabrati projekt.");
                    return;
                }
                this.setState({ selected: newItem, loading: true });

                api.post("satnica/update", newItem, this.saveSuccess, this.saveFail);
                console.log(newItem);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    saveSuccess = (data) => {
        if (data.ErrorMessage) {
            this.setState({ showModal: false });
            message.error(data.ErrorMessage);
        } else {
            this.setState({ showModal: false });
            if (this.selectedSatnicaIndex > -1) {
                let newData = this.state.data;
                newData[this.selectedSatnicaIndex] = { ...data };

                this.setState({ data: newData });
            } else {
                let newData = this.state.data;
                newData.push(data);
                this.setState({ data: newData });
            }
        }

        this.setState({ loading: false });
        //document.getElementById("satnice").scrollTop = this.scrollTop;
        this.selectLast();
    };

    saveFail = () => {};

    showModal = (item, index) => {
        let frmRef = this.state.formRef;
        if (item == null) {
            this.selectedSatnicaIndex = -1;
            this.state.formRef.current.resetFields();
            this.setState({ formRef: frmRef, projekt: null, showModal: true, selected: null, step: 0, projekti: this.sviProjekti });
        } else {
            let newItem = { ...item };
            this.selectedSatnicaIndex = index;
            newItem.Datum = moment(item.Datum);

            frmRef.current.setFieldsValue(newItem);
            this.setState({ formRef: frmRef, projekt: newItem, showModal: true, selected: item, step: 3, projekti: this.sviProjekti });
        }
    };

    delete = () => {
        this.setState({ loading: true, showModal: false });
        api.post("satnica/delete", this.state.selected, this.deleteSuccess, this.deleteFail);
    };

    deleteSuccess = () => {
        let ar = this.state.data.filter((item) => !(item.SatnicaID == this.state.selected.SatnicaID));
        this.setState({ data: ar, loading: false });
        this.selectLast();
    };

    deleteFail = () => {
        this.setState({ loading: true });
    };

    hideModal = () => this.setState({ showModal: false, step: -1 });

    changeStep = (step) => {
        this.setState({ step: step });
    };

    onSearch = (e) => {
        let newList = [...this.sviProjekti];
        let searchList = newList.filter((x) => x.Naziv.toLowerCase().includes(e) || x.Sifra.toString().toLowerCase().includes(e));
        this.setState({ projekti: searchList });
    };

    header = () => {
        return (
            <Affix offsetTop={0}>
                <Row className="satnica-header" style={{ paddingLeft: "20px" }}>
                    <Col span={1}></Col>
                    <Col span={2}>Datum</Col>
                    <Col span={4}>Projekt</Col>
                    <Col span={1}>Kol...</Col>
                    <Col span={10}>Opis</Col>
                </Row>
            </Affix>
        );
    };

    changeProject = (item) => {
        this.setState({ projekt: item });
    };

    handleChangePage = (page) => {
        this.setState({
            current: page,
            minIndex: (page - 1) * itemsPerPage,
            maxIndex: page * itemsPerPage
        });
        this.load(page);
    }

    render() {

        let isMobileDevice = Util.isMobileDevice();

        let style = isMobileDevice ?  { height: "calc(100vh - 160px)", overflow: "scroll", overflowX: "hidden", textAlign: "left" } :  { height: "calc(100vh - 260px)", overflow: "scroll", overflowX: "hidden", textAlign: "left" };

        let satnicaFormStyle = isMobileDevice ? { top: 20 } : {};

        let modalFooter =
            this.state.step === 3
                ? [
                      this.state.selected && (
                          <Popconfirm key="pop" title="Želite li obrisati stavku?" onConfirm={this.delete} okText="Da" cancelText="Ne">
                              <Button>Briši</Button>
                          </Popconfirm>
                      ),
                      <Button key="submit" onClick={this.hideModal}>
                          Zatvori
                      </Button>,
                      <Button key="back" onClick={this.saveChanges}>
                          Spremi
                      </Button>,
                  ]
                : [
                      <Button key="submit" onClick={() => this.setState({ step: 3 })}>
                          Povratak
                      </Button>,
                  ];

        if (this.state.projekt == null && this.state.step !== 3)
            modalFooter = [
                <Button key="submit" onClick={() => this.hideModal()}>
                    Zatvori
                </Button>,
            ];

        return this.state.loading ? (
            <Spinner></Spinner>
        ) : (
            <Row type="flex" justify="center" align="middle" style={{}}>
                <Col span="24" style={{ textAlign: "center" }}>
                    <Modal style={satnicaFormStyle} forceRender footer={modalFooter} title="" visible={this.state.showModal} closable={false} onCancel={this.hideModal}>
                        <SatnicaForm onSearch={this.onSearch} step={this.state.step} changeStep={this.changeStep} changeProject={this.changeProject} projekt={this.state.projekt} kategorije={this.state.kategorije} projekti={this.state.projekti} formRef={this.state.formRef}></SatnicaForm>
                    </Modal>
                    <div id="satnice" className="satnice" style={{justifyContent:"center"}} /*style={style}*/>
                        {/* {!isMobileDevice && this.header()} */}
                        {this.state.projekti && this.state.projekti.length > 0 ? (
                            this.state.data.map((item, index) => {
                                this.lastItemID = "day" + index;

                                let prazan = true;
                                if (item.Kolicina) prazan = false;

                                let cname = "satnica-dan-row";
                                if (index === this.state.data.length - 1) cname = "satnica-dan-row today";

                                return isMobileDevice ? (
                                    <div className={"satnica-row"} style={{ fontSize: "10px" }} id={"day" + index} key={"satnicao" + index} onClick={() => this.showModal(item, index)}>
                                        <Row style={{ fontWeight: "bold" }}>
                                            {Util.datum(item.Datum)}
                                        </Row>
                                        <Row  style={{ fontWeight: "bold" }}>
                                            {item.ItemNaziv}
                                        </Row>
                                        <Row >
                                            Količina (sati) - <b>{item.Kolicina}</b>

                                            {item.KolicinaPrekovremeni && item.KolicinaPrekovremeni > 0 ? (
                                                <>
                                                     
                                                    - Prekovremeni (sati) - <b>{item.KolicinaPrekovremeni}</b>
                                                </>
                                            ) : null}

                                        </Row>
                                        <Row >{item.Opis}</Row>

                                    </div>
                                ) : (
                                    <Row className={"satnica-row"} id={"day" + index} style={{width:"80%", margin:"5px 10%"}} key={"satnicao" + index} onClick={() => this.showModal(item, index)}>
                                        <Col className={"m-satnica-dan-cell"} style={{ fontWeight: "bold"}} span={12}>
                                            {Util.datum(item.Datum)} - {item.ItemNaziv}
                                        </Col>
                                        <Col className={"m-satnica-dan-cell"} span={12} >
                                            Količina (sati) - <b>{item.Kolicina}</b>
                                        </Col>
                                        <Col className={"m-satnica-dan-cell"} span={12} >
                                            {item.Opis}
                                        </Col>
                                        <Col className={"m-satnica-dan-cell"} span={12} >
                                            {item.KolicinaPrekovremeni && item.KolicinaPrekovremeni > 0 ? (
                                                <>
                                                    {" "}
                                                    Prekovremeni (sati) - <b>{item.KolicinaPrekovremeni}</b>
                                                </>
                                            ) : null}
                                        </Col>
                                    </Row>
                                );
                            }) 
                        ) : (
                            <p className="grey">Nemate dodijeljenih projekata. Molimo obratite se administratoru projekata.</p>
                        )} 
                    </div>
                    <br></br>
                    {this.state.projekti && this.state.projekti.length > 0 ? (
                        <Row className="pagination-row" align="center">
                            <Pagination
                                pageSize={itemsPerPage}
                                current={this.state.current}
                                total={this.state.numberOfPages}
                                onChange={this.handleChangePage}
                                style={{ bottom: "0px" }} />
                        </Row>
                    ) : null}
                    {this.state.projekti && this.state.projekti.length > 0 && <img onClick={() => this.showModal()} className="add-button" src={process.env.PUBLIC_URL + "/add.png"} alt=""></img>}
                </Col>
            </Row>
        );
    }
}
export default UnosAktivnosti;
