import React from "react";
import api from "../util/api";
import { Row, Col, ConfigProvider, Button, Modal, Form, Input, DatePicker, InputNumber, Slider, Popconfirm, Progress, message } from "antd";
import locale from "antd/es/locale/hr_HR";
import Spinner from "../components/spinner";
import Util from "../util/util";
import moment from "moment";
const { TextArea } = Input;

class ZavrsenostProjekt extends React.Component {
    constructor(props) {
        super(props);
        let params = Util.getParametersFromUrl(window.location.href);
        this.state = { data: [], loading: true, isMobileDevice: Util.isMobileDevice(), ProjektID: params.id, Naziv: params.naziv, Sifra: params.sifra };
        this.reload();
    }

    reload = () => {
        api.post("zavrsenostprojekta/get", { ProjektID: this.state.ProjektID }, this.successZP, this.failZP);
    };

    successZP = (data) => {
        if (data.ErrorMessage) {
            this.setState({ loading: false });
            message.error(data.ErrorMessage);
        } else {
            this.setState({ zp: data, projekt: data, loading: false });
            this.selectLast();
        }
    };

    failZP = (data) => {
        this.setState({ loading: false });
        if (data.ErrorMessage) {
            message.error(data.ErrorMessage);
        }
    };

    saveChanges = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                let newValues = this.formRef.current.getFieldsValue();
                let newItem = { ...this.state.selected, ...newValues };
                newItem.ProjektID = this.state.ProjektID;

                if (this.state.selectedZP) newItem.ZavrsenostProjektaID = this.state.selectedZP.ZavrsenostProjektaID;

                this.setState({ selected: newItem, loading: true, showModalEditor: false });
                api.post("zavrsenostprojekta/update", newItem, this.saveSuccess, this.saveFail);
            })
            .catch((err) => {});
    };

    saveSuccess = (data) => {
        //this.showZP(this.state.projekt);
        this.reload();
    };

    saveFail = () => {};

    delete = () => {
        this.setState({ loading: true });
        api.post("zavrsenostprojekta/delete", this.state.selectedZP, this.deleteSuccess, this.deleteFail);
    };

    deleteSuccess = () => {
        this.setState({ showModalEditor: false });
        this.reload();
        //this.showZP(this.state.projekt);
    };

    deleteFail = () => {
        this.setState({ loading: false });
    };

    formRef = React.createRef();

    showModalEditor = (item) => {
        if (item) {
            item.Datum = moment(item.Datum);
            this.formRef.current.setFieldsValue(item);
            this.setState({ selectedZP: item, showModalEditor: true });
        } else {
            item = {
                ProjektID: this.state.ProjektID,
                ZavrsenostProjektaID: 0,
                Datum: moment(new Date()),
                Zavrsenost: null,
                Opaska: "",
            };
            this.formRef.current.setFieldsValue(item);
            this.setState({ selectedZP: null, showModalEditor: true });
        }
    };

    hideModalEditor = () => {
        this.setState({ showModalEditor: false });
    };

    renderModal = () => {
        const dateFormat = "DD.MM.YYYY";
        const layout = {
            labelCol: { span: 7 },
            wrapperCol: { span: 15 },
        };

        const modalFooter = [
            this.state.selectedZP && (
                <Popconfirm key="pop" title="Želite li obrisati stavku?" onConfirm={this.delete} okText="Da" cancelText="Ne">
                    <Button>Briši</Button>
                </Popconfirm>
            ),
            <Button key="submit" onClick={this.hideModalEditor}>
                Zatvori
            </Button>,
            <Button key="back" onClick={this.saveChanges}>
                Spremi
            </Button>,
        ];

        return (
            <Modal forceRender title="Unos završenosti" footer={modalFooter} visible={this.state.showModalEditor} onOk={this.saveChanges} onCancel={this.hideModalEditor}>
                <ConfigProvider locale={locale}>
                    <Form {...layout} ref={this.formRef}>
                        <Row>
                            <Col span={18}>
                                <Form.Item name="Datum" label="Datum" rules={[{ required: true, message: "Molimo unesite datum" }]}>
                                    <DatePicker format={dateFormat} />
                                </Form.Item>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        <Row>
                            <Col span={18}>
                                <Form.Item label="Završenost" name="Zavrsenost" rules={[{ required: true, message: "Molimo unesite završenost" }]}>
                                    <Slider min={0} max={100} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="Zavrsenost" rules={[{ required: true, message: "Molimo unesite završenost" }]}>
                                    <InputNumber placeholder="Završenost (%)" min={0} max={100} formatter={(value) => `${value}%`} parser={(value) => value.replace("%", "")} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={18}>
                                <Form.Item label="Opaska" name="Opaska">
                                    <TextArea rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </ConfigProvider>
            </Modal>
        );
    };

    lastItemID = null;

    selectLast = () => {
        var elmnt = document.getElementById(this.lastItemID);
        if (elmnt) elmnt.scrollIntoView();
    };

    render() {
        let style = { height: "calc(100vh - 320px)", overflow: "scroll", overflowX: "hidden", marginTop: "30px" };

        return (
            <Row type="flex" justify="center" align="middle" style={{}}>
                {this.state.loading ? (
                    <Spinner></Spinner>
                ) : (
                    <Col span={24} style={{ textAlign: "center" }}>
                        <Row>
                            <Col span={20} style={{ textAlign: "left", fontWeight: "bold", fontSize: "25px", color: "#777777" }}>
                                {this.state.Sifra} - {this.state.Naziv}
                            </Col>
                        </Row>

                        <div style={style}>
                            {this.state.zp.map((item, index) => {
                                this.lastItemID = "zp" + index;

                                return this.state.isMobileDevice ? (
                                    <Row className={"list-picker"} id={"zp" + index} style={{ marginBottom: "40px", textAlign: "left" }} onClick={() => this.showModalEditor(item)} key={"item" + index}>
                                        <Col style={{ fontWeight: "bold" }} span={10}>
                                            {Util.datum(item.Datum)}
                                        </Col>
                                        <Col span={14}>
                                            <Progress percent={item.Zavrsenost} size="small" />
                                        </Col>
                                        <Col span={24}>{item.Opaska ? item.Opaska : null}</Col>
                                    </Row>
                                ) : (
                                    <Row id={"zp" + index} className="row-item" onClick={() => this.showModalEditor(item)} key={"item" + index}>
                                        <Col span={17} style={{ textAlign: "left" }}>
                                            {Util.datum(item.Datum)} {item.Opaska ? "-" : null} {item.Opaska}
                                        </Col>
                                        <Col span={7} style={{ textAlign: "right" }}>
                                            <Progress
                                                strokeColor={{
                                                    "0%": "#108ee9",
                                                    "100%": "#87d068",
                                                }}
                                                percent={item.Zavrsenost}
                                                size="small"
                                            />
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>

                        {this.state.zp.length === 0 ? <Row className="info-label">Nema podataka. </Row> : null}
                        {this.renderModal()}
                        <br></br>

                        <img onClick={() => this.showModalEditor(null)} className="add-button" src={process.env.PUBLIC_URL + "/add.png"} alt=""></img>
                    </Col>
                )}
            </Row>
        );
    }
}
export default ZavrsenostProjekt;
