import moment from "moment";

export const util = {
    getParametersFromUrl,
    isMobileDevice,
    otvoriPropis,
    OIBCheck,
    isValidEmail,
    validatePassword,
    datum,
    getCookie,
    deleteCookie,
    isIE,
    log,
    setCookie,
    getLoggedInUser,
    logout,
    CommaFormatted,
    getRoles
};

function getLoggedInUser() {
    let cooki = getCookie("user");
    let user = null;

    if (cooki) user = JSON.parse(cooki);

    if (user && user.access_token && user.authdata) {
        return user;
    } else {
        deleteCookie("user");
        return null;
        //location.reload(true);
    }
}

function logout() {
    deleteCookie("user");
    window.location.reload();
}

function log(text) {
    console.log("%c" + text, " color: black ; font-weight: bold ; " + "font-size: 20px ; font-style: italic ; text-decoration: underline ; " + "font-family: 'american typewriter' ;");
}

function isIE() {
    let ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
}

function setCookie(cookieName, value, expirationDate) {
    if (expirationDate) {
        document.cookie = cookieName + "=" + value + "; path=/; expires=" + expirationDate.toUTCString();
    } else {
        document.cookie = cookieName + "=" + value + "; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT"
    }
}

function deleteCookie(name) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function getParametersFromUrl(url) {
    let params = {};
    (url + "?")
        .split("?")[1]
        .split("&")
        .forEach(function (pair) {
            pair = (pair + "=").split("=").map(decodeURIComponent);
            if (pair[0].length) {
                params[pair[0]] = pair[1];
            }
        });
    return params;
}

function isMobileDevice() {
    let isIE = this.isIE();

    const isMobile = window.matchMedia("only screen and (max-width: 760px)");
    if (((/Mobi|Tablet|iPad|iPhone/i.test(navigator.userAgent) || isMobile.matches) && !isIE) || (isIE && isMobile.matches)) {
        return true;
    }
    return false;
}

function otvoriPropis(propis) {
    window.location = "#/propis/" + propis.UID;
}

function OIBCheck(oib) {
    oib = oib.toString();

    if (oib.length != 11) return false;

    var isNumeric = parseInt(oib, 10);

    if (isNaN(isNumeric)) return false;

    var a = 10;
    for (var i = 0; i < 10; i++) {
        a = a + parseInt(oib.substr(i, 1), 10);
        a = a % 10;
        if (a == 0) a = 10;
        a *= 2;
        a = a % 11;
    }

    var crc = 11 - a;
    if (crc == 10) crc = 0;
    return crc == parseInt(oib.substr(10, 1));
}

function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validatePassword(str) {
    if (str.length < 6) {
        return "Minimalni broj znakova je 6";
    } else if (str.search(/\d/) == -1) {
        return "Lozinka mora sadržavati minimalno jednu znamenku";
    } else if (str.search(/[a-zA-Z]/) == -1) {
        return "Lozinka mora sadžavati barem jedno slovo";
    }

    // else if (str.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+]/) != -1) {
    //     return("bad_char");
    // }
    return "ok";
}

function datum(date) {
    if (!date) return "-";

    return moment(date).format("DD.MM.YYYY");
}

function CommaFormatted(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function getRoles(){
    let roles = [];
    let cookie = getCookie("roles")
    if(cookie)
        roles = cookie.split(",");

    return roles;
}

export default util;
