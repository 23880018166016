import PersonBase from "../../components/person";
class Person extends PersonBase{
    render() {
        return (
            <div>
                Mobile:<br>
                
                </br>

                {this.details()}
            </div>
        );
    }
}
export default Person;
