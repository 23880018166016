import PersonBase from "../../components/person"
class Person extends PersonBase {
    render() {
        return (
            <div>
                Desktop:
                {this.state.username}
                
                {this.details()}
            </div>
        );
    }
}
export default Person;
