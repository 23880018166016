import React from "react";
import api from "../util/api";
import { message, Row, Button, Form, Input } from "antd";
import Spinner from "../components/spinner";

class PasswordChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  loading: false };
    }

    success = (data) => {
        this.setState({ loading: false });
        message.info("Uspješno ste promijenili lozinku.");
    };

    fail = () => {
        this.setState({ loading: false });
    };

    update = () => {
        this.setState({ loading: true });
        this.formRef.current
            .validateFields()
            .then((values) => {         
                this.setState({ loading: true });
                api.post("password/update", values, this.updateSuccess, this.updateFail);
            })
            .catch((err) => {});
    };

    updateSuccess = (data) => {
        this.setState({ loading: false });
        if(data.ErrorMessage){
            message.error(data.ErrorMessage);
        }else{
            message.info("Uspješno ste promijenili lozinku!");
        }
    };

    updateFail = () => {
        this.setState({ loading: false });
    };

    formRef = React.createRef();

    render() {
        const layout = {
            labelCol: { span: 10 },
            wrapperCol: { span: 15 },
        };

        return (
            <Row type="flex" justify="center" align="middle" style={{}}>
                {this.state.loading ? (
                    <Spinner></Spinner>
                ) : (
                    <Form {...layout} red style={{ width: "400px" }} ref={this.formRef} className="login-form">
                        <Form.Item label="Stara lozinka" name="OldPassword" rules={[{ required: true, message: "Molimo unesite staru lozinku" }]}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item label="Nova lozinka" name="NewPassword" rules={[{ required: true, message: "Molimo unesite novu lozinku" }]}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item label="Potvrdite novu lozinku" name="ConfirmPassword" rules={[{ required: true, message: "Molimo potvrdite novu lozinku" }]}>
                            <Input.Password />
                        </Form.Item>
                        <Button style={{ float:"right" }} onClick={() => this.update()} type="primary">
                            Spremi
                        </Button>
                    </Form>
                )}
            </Row>
        );
    }
}
export default PasswordChange;
