import React from "react";
import LineIcon from "react-lineicons";
import { Row, Col, Divider, Button, Modal, Form, Input, Popconfirm, Select, InputNumber, Pagination, DatePicker, Switch } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = { columns: props.columns, sortIndex: props.sortIndex, sortOrder: props.sortOrder };
    }

    onClick = (index) => {


        if(index===this.state.sortIndex){
            if (this.state.sortOrder === 0) {
                this.setState({ sortOrder: 1 });
            } else {
                this.setState({ sortOrder: 0 });
            }
        }else{
            this.setState({ sortOrder: 0, sortIndex: index });
        }


    };

    offset = this.props.noOffset ? 0 : 1;

    render() {
        return (
            <Row className="header">
                {this.state.columns.map((col, index) => (
                    <Col style={{cursor:"pointer"}} className={"noselect " + col.className} key={"hdrcol" + index} span={col.width} offset={index === 0 ? this.offset : 0} onClick={() => this.onClick(index)}>
                        {col.name}
                        {this.state.sortIndex === index && (this.state.sortOrder === 0 ? <CaretUpOutlined></CaretUpOutlined> : <CaretDownOutlined></CaretDownOutlined>)}
                    </Col>
                ))}
            </Row>
        );
    }
}
export default Header;
