import { Menu } from "antd";
import React from "react";
import { UserOutlined, ProjectOutlined, PercentageOutlined, EditOutlined, LoginOutlined, LogoutOutlined, BarChartOutlined  } from "@ant-design/icons";
import { Link } from "react-router-dom";

import Util from "../util/util";

const { SubMenu } = Menu;

class MainMenu extends React.Component {
    logout = () => {
        Util.logout();
        window.location = "/#/login";
    };

    render() {
        let isLoggedIn = this.props.isLoggedIn;
        let isMobileDevice = Util.isMobileDevice();

        let roles = Util.getRoles();
        let standarniUpisnik = roles.includes("1");
        let administratorProjekata = roles.includes("2");
        let administratorBaze = roles.includes("4");
        let voditelj = roles.includes("8");
        let administrativniIzvjestaji = roles.includes("16");
        let ostaliIzvjestaji = roles.includes("32");

        return (
            <Menu theme="dark" mode={isMobileDevice ? "horizontal" : "inline"} collapsedWidth="50">
                {isLoggedIn && standarniUpisnik ? (
                    <Menu.Item key="1" icon={<EditOutlined />}>
                        <Link onClick={this.onLinkClick} to="/dodaj">
                            Unos aktivnosti
                        </Link>
                    </Menu.Item>
                ) : null}

                {isLoggedIn && voditelj ? (
                    <Menu.Item key="1zavrsenost" icon={<PercentageOutlined />}>
                        <Link onClick={this.onLinkClick} to="/zp">
                            Završenost
                        </Link>
                    </Menu.Item>
                ) : null}

                {isLoggedIn && administratorProjekata ? (
               
                        <SubMenu key="sub-projkeit" title="Projekti" icon={<ProjectOutlined />}>
                            <Menu.Item key="s2i0-1-1">
                                <Link onClick={this.onLinkClick} to="/projekti">
                                    Popis projekata
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="s2i0-1-12">
                                <Link onClick={this.onLinkClick} to="/partneri">
                                    Partneri
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                
                ) : null}

                {isLoggedIn && (administrativniIzvjestaji || ostaliIzvjestaji) ? (
                    <Menu.Item key="1izvjestaji" icon={<BarChartOutlined />}>
                        <Link onClick={this.onLinkClick} to="/izvjestaji">
                            Izvještaji
                        </Link>
                    </Menu.Item>
                ) : null}
                

                {/* {isLoggedIn && (
                    <SubMenu key="sub1" icon={<LineChartOutlined />} title="Izvještaji">
                        <Menu.Item key="3">
                            <Link onClick={this.onLinkClick} to="/person/ivica1998/pdsiuh74">
                                Godišnji
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="4">
                            <Link onClick={this.onLinkClick} to="/person/franjo1989/sdf793ld">
                                Mjesečni
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="5">
                            <Link onClick={this.onLinkClick} to="/person/mirko675/98sdjsid">
                                Tjedni
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                )} */}

                {isLoggedIn && administratorBaze ? (
                    <SubMenu key="sub2" icon={<UserOutlined />} title="Administracija">
                        <Menu.Item key="s2i0-1-02">
                            <Link onClick={this.onLinkClick} to="/pregledkorisnika">
                                Pregled korisnika
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item key="s2i0-1-izvještaji">
                            <Link onClick={this.onLinkClick} to="/izvjestaji">
                                Izvještaji
                            </Link>
                        </Menu.Item> */}
                        <SubMenu key="sub-sub2" title="Šifrarnici">
                            {/* <Menu.Item key="s2i0-1-1">
                                <Link onClick={this.onLinkClick} to="/projekti">
                                    Projekti
                                </Link>
                            </Menu.Item> */}
                            <Menu.Item key="s2i0">
                                <Link onClick={this.onLinkClick} to="/korisnici">
                                    Korisnici
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="s2i1">
                                <Link onClick={this.onLinkClick} to="/lokacije">
                                    Lokacije
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="s2i2">
                                <Link onClick={this.onLinkClick} to="/struke">
                                    Struke
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="s2i3">
                                <Link onClick={this.onLinkClick} to="/rangovi">
                                    Rangovi
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="s2i4">
                                <Link onClick={this.onLinkClick} to="/grupe">
                                    Grupe
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="s2i5">
                                <Link onClick={this.onLinkClick} to="/blagdani">
                                    Blagdani
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                    </SubMenu>
                ) : null}

                {/* {isLoggedIn && (
                    <Menu.Item key="6" icon={<FormOutlined />}>
                        <Link onClick={this.onLinkClick} to="/sampleform">
                            Primjer forme
                        </Link>
                    </Menu.Item>
                )} */}

                {/* {isLoggedIn && (
                    <Menu.Item key="7" icon={<InfoCircleFilled />}>
                        <Link onClick={this.onLinkClick} to="/about">
                            About
                        </Link>
                    </Menu.Item>
                )} */}

                {isLoggedIn ? (
                    <Menu.Item key="8" icon={<LogoutOutlined />} onClick={this.logout}>
                        Odjava
                    </Menu.Item>
                ) : (
                    <Menu.Item key="9" icon={<LoginOutlined />}>
                        <Link to="/login">Prijava</Link>
                    </Menu.Item>
                )}
            </Menu>
        );
    }
}
export default MainMenu;
