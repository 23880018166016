import React from "react";
import api from "../util/api";
import Util from "../util/util";
import { Row, Col, Card, Button, ConfigProvider, Form, DatePicker, Input, Select, Dropdown, Menu, Tabs } from "antd";
import { DownloadOutlined, FilePdfOutlined, FileWordOutlined, FileExcelOutlined } from "@ant-design/icons";
import locale from "antd/es/locale/hr_HR";
import LineIcon from "react-lineicons";
import Spinner from "../components/spinner";
import moment from "moment";
const { Option } = Select;
const { TabPane } = Tabs;

class Izvjestaji extends React.Component {
    constructor(props) {
        super(props);
        this.state = { projekti: [], zakljucnice: [], isMobileDevice: Util.isMobileDevice(), data: [], loading: true, showEditor: false, analizaAktivnostiLoading: false, obracuniSkupnoLoading: false, projektLoading: false, projektSumarnoLoading: false };

        if (!props.data) {
            this.load();
        } else {
            this.state = { data: props.data, loading: false };
        }
    }

    load = () => {
        api.get("izvjestaji/get", null, this.success, this.fail);
    };

    success = (data) => {
        this.setState({ projekti: data.Projekti, zakljucnice: data.Zakljucnice, loading: false });
        let zz = data.Zakljucnice[0] || null; //zadnja zakljucnica

        if (zz) {
            this.analizaAktivnostiFrm.current.setFieldsValue({ DatumOd: moment(zz.DatumOd).add(1, "days"), DatumDo: moment(new Date()) });
            this.obracuniSkupnoFrm.current.setFieldsValue({ Datum: moment(new Date()) });
            this.pregledKorisnikaFrm.current.setFieldsValue({ ZakljucnicaID: 0 });
        }
    };

    saveSuccess = (data) => {
        this.setState({ data: data, loading: false });
    };

    analizaAktivnostiPreuzimanjeSuccess = () => {
        this.setState({ analizaAktivnostiLoading: false });
    };

    projektPreuzimanjeSuccess = (data) => {
        this.setState({ projektLoading: false });
    };

    projektSumarnoPreuzimanjeSuccess = (data) => {
        this.setState({ projektSumarnoLoading: false });
    };

    obracuniSkupnoPreuzimanjeSuccess = (data) => {
        this.setState({ obracuniSkupnoLoading: false });
    };

    pregledKorisnikaPreuzimanjeSuccess = (data) => {
        this.setState({ pregledKorisnikaLoading: false });
    };

    resumePreuzimanjeSuccess = (data) => {
        this.setState({ resumeLoading: false });
    };

    ivjestajOnClick = (izvjestaj) => {};

    analizaAktivnostiFrm = React.createRef();
    obracuniSkupnoFrm = React.createRef();
    projektFrm = React.createRef();
    projektSumarnoFrm = React.createRef();
    pregledKorisnikaFrm = React.createRef();
    resumeFrm = React.createRef();

    preuzmi = (izvjestaj, type) => {
        izvjestaj.current
            .validateFields()
            .then((values) => {
                if (values.DatumOd) values.DatumOd = values.DatumOd.toDate().toISOString();

                if (values.DatumDo) values.DatumDo = values.DatumDo.toDate().toISOString();

                if (values.Datum) values.Datum = values.Datum.toDate();

                let data = { ProjektID: null, DatumOd: null, DatumDo: null, ReportType: type };

                data = { ...data, ...values };

                let url = "";
                let preuzimanjeSuccess = null;
                let self = this;
                if (izvjestaj === self.analizaAktivnostiFrm) {
                    url = "reports/analizaaktivnosti";
                    preuzimanjeSuccess = this.analizaAktivnostiPreuzimanjeSuccess;
                    this.setState({ analizaAktivnostiLoading: true });
                }
                if (izvjestaj === self.projektFrm) {
                    url = "reports/projekt";
                    preuzimanjeSuccess = this.projektPreuzimanjeSuccess;
                    this.setState({ projektLoading: true });
                }
                if (izvjestaj === self.projektSumarnoFrm) {
                    url = "reports/projektsumarno";
                    preuzimanjeSuccess = this.projektSumarnoPreuzimanjeSuccess;
                    this.setState({ projektSumarnoLoading: true });
                }
                if (izvjestaj === self.obracuniSkupnoFrm) {
                    url = "reports/obracuniskupno";
                    preuzimanjeSuccess = this.obracuniSkupnoPreuzimanjeSuccess;
                    this.setState({ obracuniSkupnoLoading: true });
                }
                if (izvjestaj === self.pregledKorisnikaFrm) {
                    url = "reports/pregledkorisnika";
                    preuzimanjeSuccess = this.pregledKorisnikaPreuzimanjeSuccess;
                    this.setState({ pregledKorisnikaLoading: true });
                }
                if (izvjestaj === self.resumeFrm) {
                    url = "reports/resume";
                    preuzimanjeSuccess = this.resumePreuzimanjeSuccess;
                    this.setState({ resumeLoading: true });
                }
                api.getReport(url, data, preuzimanjeSuccess, null);
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    menuClick = (e) => {
        alert(e);
    };

    getOverlay = (izvjestaj) => {
        return (
            <Menu>
                <Menu.Item onClick={() => this.preuzmi(izvjestaj, "PDF")} icon={<FilePdfOutlined />}>
                    PDF
                </Menu.Item>
                <Menu.Item onClick={() => this.preuzmi(izvjestaj, "Excel")} icon={<FileExcelOutlined />}>
                    EXCEL
                </Menu.Item>
                {/* <Menu.Item onClick={() => this.preuzmi(izvjestaj, "Word")} icon={<FileWordOutlined />}>
                    WORD
                </Menu.Item> */}
            </Menu>
        );
    };

    getGodine = () => {
        let godine = [];
        let godina = new Date().getFullYear();
        for (let i = godina; i > godina - 20; i--) {
            godine.push(i);
        }
        return godine;
    };

    selectedFrm = null;

    tabChange = (index) => {
        if (index === 1) {
            this.selectedFrm = this.analizaAktivnostiFrm;
        }
        if (index === 2) {
            this.selectedFrm = this.obracuniSkupnoFrm;
        }
        if (index === 3) {
            this.selectedFrm = this.projektFrm;
        }
        if (index === 4) {
            this.selectedFrm = this.projektSumarnoFrm;
        }
    };

    onTabChange = () => {
        let godina = new Date().getFullYear();
        let mjesec = new Date().getMonth() + 1;

        if (this.resumeFrm.current) {
            let resumeValues = this.resumeFrm.current.getFieldsValue();
            resumeValues.Godina = godina;
            resumeValues.Mjesec = mjesec;
            this.resumeFrm.current.setFieldsValue({ Godina: godina, Mjesec: mjesec });
        }
    };

    render() {
        let width = 600;

        let godina = new Date().getFullYear();
        let mjesec = new Date().getMonth() + 1;

        const layout = {
            labelCol: { span: 14 },
            wrapperCol: { span: 10 },
        };

        const col1Span = 12;
        const col2Span = 12;
        const dateFormat = "DD.MM.YYYY";

        const projekti = this.state.projekti.map((x) => (
            <Option value={x.ProjektID} key={x.ProjektID}>
                {x.Sifra} - {x.Naziv}
            </Option>
        ));

        const zakljucnice = this.state.zakljucnice.map((x) => (
            <Option value={x.ZakljucnicaID} key={"zak" + x.ZakljucnicaID}>
                Zaključnica {Util.datum(x.DatumDo)}
            </Option>
        ));

        zakljucnice.unshift(
            <Option value={0} key={"zak0"}>
                - trenutno stanje -
            </Option>
        );

        let tabPosition = "left";
        if (this.state.isMobileDevice) {
            tabPosition = "top";
        }

        let roles = Util.getRoles();
        let ostaliIzvjestaji = roles.includes("32");
        let administrativniIzvjestaji = roles.includes("16");

        return (
            <Row type="flex" justify="center" align="middle" style={{}}>
                {this.state.loading ? (
                    <Spinner></Spinner>
                ) : (
                    <Col span={24}>
                        <ConfigProvider locale={locale}>
                            <Tabs tabPosition={tabPosition} defaultActiveKey="1" onChange={this.onTabChange}>
                                {(ostaliIzvjestaji) && (
                                    <TabPane forceRender tab="Analiza aktivnosti" key="1">
                                        <Card style={{ width: width }} className="report-card">
                                            <Row>
                                                <Col span={col1Span}>
                                                    <Form ref={this.analizaAktivnostiFrm}>
                                                        <Form.Item label="Datum od" name="DatumOd" rules={[{ required: true, message: "Molimo unesite datum od" }]}>
                                                            <DatePicker placeholder="Datum od" format={dateFormat} />
                                                        </Form.Item>
                                                        <Form.Item label="Datum do" name="DatumDo" rules={[{ required: true, message: "Molimo unesite datum do" }]}>
                                                            <DatePicker placeholder="Datum do" format={dateFormat} />
                                                        </Form.Item>
                                                    </Form>
                                                </Col>
                                                <Col span={col2Span} align="right">
                                                    <Dropdown overlay={this.getOverlay(this.analizaAktivnostiFrm)} placement="bottomCenter" icon={<DownloadOutlined />}>
                                                        <Button type="primary" loading={this.state.analizaAktivnostiLoading}>
                                                            Preuzmi <DownloadOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                )}

                                {(ostaliIzvjestaji) && (
                                    <TabPane forceRender tab="Obračuni skupno" key="2">
                                        <Card style={{ width: width }} className="report-card">
                                            <Row>
                                                <Col span={col1Span}>
                                                    <Form ref={this.obracuniSkupnoFrm}>
                                                        <Form.Item label="Datum" name="Datum" rules={[{ required: true, message: "Molimo unesite datum" }]}>
                                                            <DatePicker placeholder="Datum" format={dateFormat} />
                                                        </Form.Item>
                                                    </Form>
                                                </Col>
                                                <Col span={col2Span} align="right">
                                                    <Dropdown overlay={this.getOverlay(this.obracuniSkupnoFrm)} placement="bottomCenter" icon={<DownloadOutlined />}>
                                                        <Button type="primary" loading={this.state.obracuniSkupnoLoading}>
                                                            Preuzmi <DownloadOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                )}

                                {(administrativniIzvjestaji) && (
                                    <TabPane forceRender tab="Projekt" key="3">
                                        <Card style={{ width: width }} className="report-card">
                                            <Row>
                                                <Col span={col1Span}>
                                                    <Form ref={this.projektFrm}>
                                                        <Form.Item name="ProjektID" rules={[{ required: true, message: "Molimo odaberite projekt" }]}>
                                                            <Select
                                                                style={{ width: 300 }}
                                                                allowClear
                                                                placeholder="Odaberi projekt"
                                                                showSearch
                                                                filterOption={(input, option) => {
                                                                    return option.children.concat().toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                                }}
                                                            >
                                                                {projekti}
                                                            </Select>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>
                                                <Col span={col2Span} align="right">
                                                    <Dropdown overlay={this.getOverlay(this.projektFrm)} placement="bottomCenter" icon={<DownloadOutlined />}>
                                                        <Button type="primary" loading={this.state.projektLoading}>
                                                            Preuzmi <DownloadOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                )}

                                {(ostaliIzvjestaji) && (
                                    <TabPane forceRender tab="Projekt sumarno" key="4">
                                        <Card style={{ width: width }} className="report-card">
                                            <Row>
                                                <Col span={col1Span}>
                                                    <Form ref={this.projektSumarnoFrm}>
                                                        <Form.Item name="ProjektID" rules={[{ required: true, message: "Molimo odaberite projekt" }]}>
                                                            <Select
                                                                style={{ width: 300 }}
                                                                allowClear
                                                                placeholder="Odaberi projekt"
                                                                showSearch
                                                                filterOption={(input, option) => {
                                                                    return option.children.concat().toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                                }}
                                                            >
                                                                {projekti}
                                                            </Select>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>
                                                <Col span={col2Span} align="right">
                                                    <Dropdown overlay={this.getOverlay(this.projektSumarnoFrm)} placement="bottomCenter" icon={<DownloadOutlined />}>
                                                        <Button type="primary" loading={this.state.projektSumarnoLoading}>
                                                            Preuzmi <DownloadOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                )}

                                {administrativniIzvjestaji && (
                                    <TabPane forceRender tab="Pregled korisnika" key="5">
                                        <Card style={{ width: width }} className="report-card">
                                            <Row>
                                                <Col span={col1Span}>
                                                    <Form ref={this.pregledKorisnikaFrm}>
                                                        <Form.Item name="ZakljucnicaID" rules={[{ required: true, message: "Molimo odaberite zaključnicu" }]}>
                                                            <Select style={{ width: 300 }} placeholder="Odaberi zaključnicu">
                                                                {zakljucnice}
                                                            </Select>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>
                                                <Col span={col2Span} align="right">
                                                    <Dropdown overlay={this.getOverlay(this.pregledKorisnikaFrm)} placement="bottomCenter" icon={<DownloadOutlined />}>
                                                        <Button type="primary" loading={this.state.pregledKorisnikaLoading}>
                                                            Preuzmi <DownloadOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                )}

                                {administrativniIzvjestaji && (
                                    <TabPane forceRender tab="Rezime" key="6">
                                        <Card style={{ width: width }} className="report-card">
                                            <Row>
                                                <Col span={col1Span}>
                                                    <Form ref={this.resumeFrm}>
                                                        <Form.Item name="Mjesec" label="Mjesec">
                                                            <Select style={{ width: 100 }}>
                                                                <Option key="m1" value="1">
                                                                    1
                                                                </Option>
                                                                <Option key="m2" value="2">
                                                                    2
                                                                </Option>
                                                                <Option key="m3" value="3">
                                                                    3
                                                                </Option>
                                                                <Option key="m4" value="4">
                                                                    4
                                                                </Option>
                                                                <Option key="m5" value="5">
                                                                    5
                                                                </Option>
                                                                <Option key="m6" value="6">
                                                                    6
                                                                </Option>
                                                                <Option key="m7" value="7">
                                                                    7
                                                                </Option>
                                                                <Option key="m8" value="8">
                                                                    8
                                                                </Option>
                                                                <Option key="m9" value="9">
                                                                    9
                                                                </Option>
                                                                <Option key="m10" value="10">
                                                                    10
                                                                </Option>
                                                                <Option key="m11" value="11">
                                                                    11
                                                                </Option>
                                                                <Option key="m12" value="12">
                                                                    12
                                                                </Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item name="Godina" label="Godina">
                                                            <Select style={{ width: 100 }}>
                                                                {this.getGodine().map((item, index) => {
                                                                    return (
                                                                        <Option key={"g" + item} value={item}>
                                                                            {item}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>
                                                <Col span={col2Span} align="right">
                                                    <Dropdown overlay={this.getOverlay(this.resumeFrm)} placement="bottomCenter" icon={<DownloadOutlined />}>
                                                        <Button type="primary" loading={this.state.resumeLoading}>
                                                            Preuzmi <DownloadOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                )}
                            </Tabs>
                        </ConfigProvider>
                    </Col>
                )}
            </Row>
        );
    }
}
export default Izvjestaji;
