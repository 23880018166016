import React from "react";
import api from "../util/api";
import { Row, Col, Divider, Button, Modal, Form, Input, Popconfirm, Tooltip } from "antd";
import LineIcon from "react-lineicons";
import Spinner from "../components/spinner";

class Lokacije extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], loading: true, showEditor: false };

        if (!props.data) {
            this.load();
        } else {
            this.state = { data: props.data, loading: false };
        }
    }

    load = () => {
        api.get("lokacija/getall", null, this.success, this.fail);
    };

    success = (data) => {
        this.setState({ data: data, loading: false });
    };

    fail = () => {
        this.setState({ data: null, loading: false });
    };

    showEditor = (item) => {
        if (item == null) {
            this.setState({ showEditor: true, selected: null });
            this.formRef.current.resetFields();
        } else {
            this.setState({ showEditor: true, selected: item });
            this.formRef.current.setFieldsValue(item);
        }
    };

    hideEditor = () => {
        this.setState({ showEditor: false });
    };

    saveChanges = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                let newValues = this.formRef.current.getFieldsValue();
                let newItem = { ...this.state.selected, ...newValues };

                this.setState({ selected: newItem, loading: true, showEditor: false });

                api.post("lokacija/update", newItem, this.saveSuccess, this.saveFail);
            
            })
            .catch((err) => {
          
            });
    };

    saveSuccess = () => {
        this.load();
    };

    saveFail = () => {};

    delete = (item) => {
        this.setState({ loading: true });
        api.post("lokacija/delete", item, this.deleteSuccess, this.deleteFail);
    };

    deleteSuccess = () => {
        this.load();
    };

    deleteFail = () => {
        this.setState({ loading: false });
    };

    formRef = React.createRef();

    renderModal = () => {
        return (
            <Modal forceRender title="Unos lokacije" visible={this.state.showEditor} onOk={this.saveChanges} onCancel={this.hideEditor}>
                <Form ref={this.formRef}>
                    <Form.Item label="Naziv" name="Naziv" rules={[{ required: true, message: "Molimo unesite naziv" }]}>
                        <Input placeholder="Naziv" />
                    </Form.Item>
                    <Form.Item label="Adresa" name="Adresa" rules={[{ required: true, message: "Molimo unesite adresu" }]}>
                        <Input placeholder="Adresa" />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };
    render() {
        return (
            <Row type="flex" justify="center" align="middle" style={{}}>
                {this.state.loading ? (
                    <Spinner></Spinner>
                ) : (
                    <Col span={24}>
                        {this.state.data.map((item, index) => {
                            return (
                                <Row key={"item" + index}>
                                    <Col span={1}>
                                        <LineIcon name="map-marker" />
                                    </Col>
                                    <Col className="item" onClick={() => this.showEditor(item)} span={22}>
                                        {item.Naziv} <br /> <span className="transparent"> {item.Adresa}</span>
                                    </Col>
                                    <Col className="item" span={1}>
                                        <Popconfirm title="Sigurni ste da želite brisati lokaciju?" onConfirm={() => this.delete(item)} okText="DA" cancelText="NE">
                                            <LineIcon name="archive" />
                                        </Popconfirm>
                                    </Col>
                                    <Divider orientation="left"></Divider>
                                </Row>
                            );
                        })}
                        {/* <Button onClick={() => this.showEditor(null)}>Dodaj</Button> */}
                        <Row className="center">
                            <img onClick={() => this.showEditor(null)} className="add-button" src={process.env.PUBLIC_URL + "/add.png"} alt=""></img>
                        </Row>
                        
                        {this.renderModal()}
                    </Col>
                )}
            </Row>
        );
    }
}
export default Lokacije;
