import React from "react";
import api from "../util/api";
import { Row, Col, Divider, DatePicker, Modal, Form, Input, Popconfirm, ConfigProvider } from "antd";
import LineIcon from "react-lineicons";
import Spinner from "../components/spinner";
import locale from "antd/es/locale/hr_HR";
import Util from "../util/util";
import moment from "moment";
import Header from "./header";

class Blagdani extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], loading: true, showEditor: false };

        if (!props.data) {
            this.load();
        } else {
            this.state = { data: props.data, loading: false };
        }
    }

    load = () => {
        api.get("blagdan/getall", null, this.success, this.fail);
    };

    success = (data) => {
        this.setState({ data: data, loading: false });
    };

    fail = () => {
        this.setState({ data: null, loading: false });
    };

    showEditor = (item) => {
        if (item == null) {
            this.setState({ showEditor: true, selected: null });
            this.formRef.current.resetFields();
        } else {
            item.Datum = moment(item.Datum);
            this.setState({ showEditor: true, selected: item });
            this.formRef.current.setFieldsValue(item);
        }
    };

    hideEditor = () => {
        this.setState({ showEditor: false });
    };

    saveChanges = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                let newValues = this.formRef.current.getFieldsValue();
                let newItem = { ...this.state.selected, ...newValues };

                this.setState({ selected: newItem, loading: true, showEditor: false });

                api.post("blagdan/update", newItem, this.saveSuccess, this.saveFail);
            })
            .catch((err) => {});
    };

    saveSuccess = () => {
        this.load();
    };

    saveFail = () => {};

    delete = (item) => {
        this.setState({ loading: true });
        api.post("blagdan/delete", item, this.deleteSuccess, this.deleteFail);
    };

    deleteSuccess = () => {
        this.load();
    };

    deleteFail = () => {
        this.setState({ loading: false });
    };

    formRef = React.createRef();

    renderModal = () => {
        const dateFormat = "DD.MM.YYYY";

        return (
            <Modal forceRender title="Unos lokacije" visible={this.state.showEditor} onOk={this.saveChanges} onCancel={this.hideEditor}>
                <ConfigProvider locale={locale}>
                    <Form ref={this.formRef}>
                        <Form.Item label="Naziv" name="Naziv" rules={[{ required: true, message: "Molimo unesite naziv" }]}>
                            <Input placeholder="Naziv" />
                        </Form.Item>
                        <Form.Item label="Datum" name="Datum" rules={[{ required: true, message: "Molimo unesite datum" }]}>
                            <DatePicker placeholder="Datum" format={dateFormat} />
                        </Form.Item>
                    </Form>
                </ConfigProvider>
            </Modal>
        );
    };
    render() {
        let columns = [
            { name: "Naziv", width: 6 },
            { name: "Datum", width: 2 }
        ];

        return (
            <Row type="flex" justify="center" align="middle" style={{}}>
                {this.state.loading ? (
                    <Spinner></Spinner>
                ) : (
                    <Col span={24}>
                        <Header columns={columns} sortIndex={-1} sortOrder={0}></Header>

                        {this.state.data.map((item, index) => {
                            return (
                                <Row key={"item" + index}  className="row-item" >
                                    <Col span={1}>
                                        {/* <LineIcon name="map-marker" /> */}
                                    </Col>
                                    <Col className="item" onClick={() => this.showEditor(item)} span={6}>
                                        {item.Naziv}
                                    </Col>
                                    <Col className="item" onClick={() => this.showEditor(item)} span={16}>
                                        {Util.datum(item.Datum)}
                                    </Col>
                                    <Col className="item" span={1}>
                                        <Popconfirm title="Sigurni ste da želite brisati blagdan?" onConfirm={() => this.delete(item)} okText="DA" cancelText="NE">
                                            <LineIcon name="archive" />
                                        </Popconfirm>
                                    </Col>
                                </Row>
                            );
                        })}
                        {/* <Button onClick={() => this.showEditor(null)}>Dodaj</Button> */}
                        <Row className="center">
                            <img onClick={() => this.showEditor(null)} className="add-button" src={process.env.PUBLIC_URL + "/add.png"} alt=""></img>
                        </Row>

                        {this.renderModal()}
                    </Col>
                )}
            </Row>
        );
    }
}
export default Blagdani;
